/**
 * Bellman-Ford Algorithm
 * @author Yiwen Jiang, Sicheng Chen
 */

export const bellmanFord = (data, from, to, speed, handleHighlightEdgesInQueue, setDisableAll, setLog, log, history, setHistory) => {
    //begin vis
    setDisableAll(true);
    let timeStep = 0;
    let steps=0;

    let edgeRenderQueue = [];
    /* edgeRenderQueue: [timeStep: [step: {id, type}, ...], ...] */

    let logList = log;

    logList.push({
        level: 'debug',
        text: 'Start running Bellman-Ford...'
    });

    const addStep = (id, type) => {
        if (edgeRenderQueue.length === timeStep) {
            /* add timeStep */
            const time = [{
                id: id,
                type: type
            }];
            edgeRenderQueue.push(time);
        } else {
            /* add step to timeStep */
            const time = edgeRenderQueue[edgeRenderQueue.length - 1];
            edgeRenderQueue[edgeRenderQueue.length - 1] = time.concat({
                id: id,
                type: type
            });
        }
    }
    //end vis

    const n = data.nodes.length;
    let dist = new Array(n).fill().map(() => new Array(n).fill(Infinity));
    let prev = new Array(n).fill().map(() => new Array(n).fill(-1));


    const getNodeNumById = (id) => {
        for (let i = 0; i < data.nodes.length; i++) {
            if (data.nodes[i].id === id) {
                return i;
            }
        }

        return -1;
    }

    const getNodeIdByNum = (num) => {
        return data.nodes.find(node => getNodeNumById(node.id) === num).id;
    }

    data.edges.forEach((edge) => {
        //begin vis
        addStep(edge.id, 'edgeVisited');
        timeStep++;
        //end vis
        dist[getNodeNumById(edge.source)][getNodeNumById(edge.target)] = edge.weight;
        prev[getNodeNumById(edge.source)][getNodeNumById(edge.target)] = getNodeNumById(edge.source);

        //begin vis
        logList.push({
            level: 'info',
            text: 'Edge '
                + data.nodes.find(node => node.id === data.edges.find(e => e.id === edge.id).source).label
                + ' -> '
                + data.nodes.find(node => node.id === data.edges.find(e => e.id === edge.id).target).label
                + ' (w='
                + data.edges.find(e => e.id === edge.id).weight
                +') visited.'
        });

        /* handle array update */
        //console.log(dist);
        //end vis
    });

    for (let i = 0; i < n; i++) {
        dist[i][i] = 0;
        prev[i][i] = i;
        //begin vis
        /* handle array update */
        //end vis
    }
    let weight=dist;
    //console.log(weight);
    const begin=getNodeNumById(from);
    for (let j = 0; j < n-1; j++) {
        for (let k = 0; k < n; k++) {
            for(let u=0;u<n;u++){
                if (dist[begin][u] > dist[begin][k] + weight[k][u]) {
                    dist[begin][u] = dist[begin][k] + weight[k][u];
                    prev[begin][u] = prev[k][u];

                    //begin vis
                    /* handle array update */
                    //end vis
                }
                steps++;
            }
            //console.log('sahdas'+steps);
        }
        //console.log(steps);
    }

    let hasNegativeCycle = false;
    for (let i = 0; i < n; i++) {
        for (let j = 0; j < n ; j++) {
            if (dist[begin][i] + weight[i][j] < dist[begin][j]) {
                hasNegativeCycle = true;
            }
        }
    }

    /* Path Recons */

    const pathRecons = (u, v) => {
        let path = [];

        if (prev[u][v] === -1) {
            return [];
        }

        path.push(v);

        while (u !== v) {
            v = prev[u][v];
            path.unshift(v);
        }

        return path;
    }

    const pathNodeNums = pathRecons(getNodeNumById(from), getNodeNumById(to));
    const pathNodeLabels = pathNodeNums.map(num => data.nodes[num].label);


    //begin vis
    for (let i = 0; i < pathNodeNums.length - 1; i++) {
        const edgeId = getEdgeId(data, getNodeIdByNum(pathNodeNums[i]), getNodeIdByNum(pathNodeNums[i + 1]));
        addStep(edgeId, 'edgeShortest');
    }

    for (let i = 0; i < edgeRenderQueue.length; i++) {
        setTimeout(() => {
            handleHighlightEdgesInQueue(edgeRenderQueue[i]);
        }, speed * i);
    }

    let shortestPathText = 'Shortest path: ';

    for (let i = 0; i < pathNodeLabels.length; i++) {
        shortestPathText = shortestPathText + pathNodeLabels[i];
        if (i !== pathNodeLabels.length - 1) {
            shortestPathText = shortestPathText + ' -> ';
        } else {
            shortestPathText = shortestPathText + '.';
        }
    }

    logList.push({
        level: 'info',
        text: shortestPathText
    });

    logList.push({
        level: 'warning',
        text: 'Total time steps: ' + edgeRenderQueue.length
    });

    setHistory({
        ...history,
        bellmanFord: steps
    });

    logList.push({
        level: 'debug',
        text: 'Bellman-Ford completed. Start rendering...'
    });

    timeStep++;

    setTimeout(() => {
        setDisableAll(false);
    }, speed * timeStep);
    //end vis
};

const getEdgeId = (data, from, to) => {
    const edge = data.edges.find(edge => (edge.source === from && edge.target === to));
    if (edge === undefined) {
        return '';
    } else {
        return edge.id;
    }
};