export function dijkstra(grid, beginNode, endNode) {
    const visitedNodes = [];
    beginNode.distance = 0;
    const unvisitedNodes = getAllNodes(grid);

    while (!!unvisitedNodes.length) {
        unvisitedNodes.sort((nodeA, nodeB) => nodeA.distance - nodeB.distance);
        const closestNode = unvisitedNodes.shift();
        if (closestNode.isWall) {
            continue;
        }
        if (closestNode.distance === Infinity) {
            return [visitedNodes, calculatePath(endNode)];
        }
        closestNode.isVisited = true;
        visitedNodes.push(closestNode);
        if (closestNode === endNode) {
            return [visitedNodes, calculatePath(endNode)];
        }
        updateUnvisitedNeighbors(closestNode, grid);
    }
}

export function getAllNodes(grid = []) {
    const nodes = [];
    for (let i = 0; i < grid.length; i++) {
        for (let j = 0; j < grid[0].length; j++) {
            nodes.push(grid[i][j]);
        }
    }
    return nodes;
}

function getUnvisitedNeighbors(node, grid) {
    const neighbors = [];
    const { column, row } = node;
    if (row > 0) neighbors.push(grid[row - 1][column]);
    if (row < grid.length - 1) neighbors.push(grid[row + 1][column]);
    if (column > 0) neighbors.push(grid[row][column - 1]);
    if (column < grid[0].length - 1) neighbors.push(grid[row][column + 1]);
    return neighbors.filter((neighbor) => !neighbor.isVisited);
}

function updateUnvisitedNeighbors(node, grid) {
    const unvisitedNeighbors = getUnvisitedNeighbors(node, grid);
    for (const neighbor of unvisitedNeighbors) {
        neighbor.distance = node.distance + 1;
        neighbor.previousNode = node;
    }
}

function calculatePath(finishNode) {
    const shortestPathNodes = [];
    let currentNode = finishNode;
    while (currentNode !== null) {
        shortestPathNodes.unshift(currentNode);
        currentNode = currentNode.previousNode;
    }
    return shortestPathNodes;
}