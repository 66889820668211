import React, {useEffect, useState} from "react";
import {Box, Select} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {changeLanguage} from "i18next";

const LanguageSelectBox = ({ display, padding }) => {
    const { t } = useTranslation(['widgets', 'common']);

    const [ lang, setLang ] = useState('default');

    useEffect(() => {
        if (lang === 'default') {} else {
            changeLanguage(lang);
        }
    }, [lang]);

    return (
        <Box display={display} padding={padding}>
            <Select fontSize={'sm'} placeholder={t('common.language-select-box.placeholder', {ns: 'widgets'})} onChange={(event) => {setLang(event.target.value)}}>
                <option value='en'>{t('common.language-select-box.en', {ns: 'widgets'})}</option>
                <option value='zh-CHS'>{t('common.language-select-box.zh-CHS', {ns: 'widgets'})}</option>
                <option value='zh-CHT'>{t('common.language-select-box.zh-CHT', {ns: 'widgets'})}</option>
                <option value='fr'>{t('common.language-select-box.fr', {ns: 'widgets'})}</option>
                <option value='de'>{t('common.language-select-box.de', {ns: 'widgets'})}</option>
                <option value='ja'>{t('common.language-select-box.ja', {ns: 'widgets'})}</option>
            </Select>
        </Box>
    );
};

export default LanguageSelectBox;