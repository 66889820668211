import React from "react";

import Graphin from '@antv/graphin';
import { Behaviors } from '@antv/graphin';
import {useColorMode, useColorModeValue} from "@chakra-ui/react";

const GraphRenderer = ( props ) => {
    const { Hoverable } = Behaviors;

    const { colorMode, setColorMode } = useColorMode();

    const bg = useColorModeValue('#F7FAFC', '#1A202C')

    const data = props.data;

    return (
        <div>
            <Graphin
                data={data}
                layout={{ type: props.layoutType }}
                theme={{ mode: colorMode==='dark'?'dark':'light', primaryColor: '#38B2AC', background: bg }}
                fitView={true}
                fitCenter={true}
            >
                <Hoverable bindType="node" />
                <Hoverable bindType="edge" />
            </Graphin>
        </div>
    );
}

export default GraphRenderer;