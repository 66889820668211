import React, {useState} from "react";
import {Box, Button, FormControl, HStack, Select, Stack, StackDivider} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {kruskal} from "../../../algorithms/graph/mst/kruskal";
import {prim} from "../../../algorithms/graph/mst/prim";
import {boruvka} from "../../../algorithms/graph/mst/boruvka";

const MSTControlPanel = ( props ) => {
    const [ speed, setSpeed ] = useState(150);

    const { t } = useTranslation(['common', 'widgets']);

    const handleRunVisualization = () => {
        switch (props.algorithm) {
            case 'kruskal':
                kruskal(props.data, speed, props.handleHighlightEdgesInQueue, props.setDisableAll, props.setLog, props.log, props.history, props.setHistory);
                break;
            case 'prim':
                prim(props.data, speed, props.handleHighlightEdgesInQueue, props.setDisableAll, props.setLog, props.log, props.history, props.setHistory);
                break;
            case 'boruvka':
                boruvka(props.data, speed, props.handleHighlightEdgesInQueue, props.setDisableAll, props.setLog, props.log, props.history, props.setHistory);
                break;
            default:
                alert(t('graph.mst-control-panel.alerts.select-algo', {ns: 'widgets'}));
                return;
        }
    };

    return (
        <Stack divider={<StackDivider />} spacing='4'>
            <Box>
                <FormControl>
                    <HStack>
                        <Select fontSize={'sm'} isDisabled={props.disableAll} value={props.algorithm} onChange={(event) => props.setAlgorithm(event.target.value)}>
                            <option disabled={true} value={''}>{t('modules.graph.mst.algorithms.algorithm', {ns: 'common'})}</option>
                            <option value="boruvka">{t('modules.graph.mst.algorithms.boruvka', {ns: 'common'})}</option>
                            <option value="kruskal">{t('modules.graph.mst.algorithms.kruskal', {ns: 'common'})}</option>
                            <option value="prim">{t('modules.graph.mst.algorithms.prim', {ns: 'common'})}</option>
                        </Select>
                        <Select fontSize={'sm'} isDisabled={props.disableAll} value={speed} onChange={(event) => setSpeed(event.target.value)}>
                            <option value={50}>{t('speeds.very-fast', {ns: 'common'})}</option>
                            <option value={100}>{t('speeds.fast', {ns: 'common'})}</option>
                            <option value={150}>{t('speeds.normal', {ns: 'common'})}</option>
                            <option value={200}>{t('speeds.slow', {ns: 'common'})}</option>
                            <option value={250}>{t('speeds.very-slow', {ns: 'common'})}</option>
                        </Select>
                        <Button
                            colorScheme='teal'
                            size='md'
                            isLoading={props.disableAll}
                            fontSize={'sm'}
                            onClick={() => handleRunVisualization()}
                        >
                            {t('graph.mst-control-panel.button.run', {ns: 'widgets'})}
                        </Button>
                        <Button
                            colorScheme='teal'
                            size='md'
                            isDisabled={props.disableAll}
                            fontSize={'sm'}
                            onClick={() => props.handleClearPath()}
                        >
                            {t('graph.mst-control-panel.button.clear-path', {ns: 'widgets'})}
                        </Button>
                    </HStack>
                </FormControl>
            </Box>
        </Stack>
    );
};

export default MSTControlPanel;