import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader, Flex,
    FormControl, FormLabel,
    Grid,
    GridItem,
    Heading,
    HStack, Input, Progress,
    Select, Spacer,
    Stack,
    StackDivider, Text,
} from "@chakra-ui/react";
import GraphRenderer from "../../../components/renderers/GraphRenderer";
import MSTControlPanel from "./MSTControlPanel";
import {useTranslation} from "react-i18next";
import {Utils} from "@antv/graphin";
import LogRenderer from "../../../components/renderers/LogRenderer";
import {MathJax} from "better-react-mathjax";
import {getMock} from "../../../utils/graph/getMock";

const GraphMSTPathPage = ({ type }) => {
    const [ data, setData ] = useState({
        nodes: [],
        edges: [],
    });
    const [ addEdgeSource, setAddEdgeSource ] = useState('');
    const [ addEdgeTarget, setAddEdgeTarget ] = useState('');
    const [ addEdgeWeight, setAddEdgeWeight ] = useState('');
    const [ delEdgeId, setDelEdgeId ] = useState('');
    const [ delNodeId, setDelNodeId ] = useState('');
    const [ letters, setLetters ] = useState([
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
        'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
        'U', 'V', 'W', 'X', 'Y', 'Z'
    ]);
    const [ nextNodeId, setNextNodeId ] = useState(0);
    const [ nextEdgeId, setNextEdgeId ] = useState(0);

    const [ disableAll, setDisableAll ] = useState(false);
    const [ isEditingGraph, setIsEditingGraph ] = useState(false);

    const [ style, setStyle ] = useState('force');

    const [ log, setLog ] = useState([{level: 'debug', text: 'App initialized.'}]);

    const [ algorithm, setAlgorithm ] = useState('');
    const [ history, setHistory ] = useState({
        boruvka: 0,
        prim: 0,
        kruskal: 0
    });

    const { t } = useTranslation(['pages', 'common']);

    const colors = {
        'primaryColor': '#38B2AC',
        'nodeSource': '#F56565',
        'nodeTarget': '#4299E1',
        'edgeVisited': '#38B2AC',
        'edgeShortest': '#ECC94B',
        'edgeMST': '#ECC94B',
    }

    useEffect(() => {
        const allLettersAllowed = [
            'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
            'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
            'U', 'V', 'W', 'X', 'Y', 'Z'
        ];

        const usedLetters = data.nodes.map((node) => node.label);
        const newLetters = allLettersAllowed.filter((letter) => !usedLetters.includes(letter));
        setLetters(newLetters);
    }, [data]);

    useEffect(() => {
        if (disableAll) {
            setAddEdgeSource('');
            setAddEdgeTarget('');
            setDelNodeId('');
            setDelEdgeId('');
        }
    }, [disableAll]);

    useEffect(() => {
        if (isEditingGraph) {
            setAddEdgeSource('');
            setAddEdgeTarget('');
            setDelNodeId('');
            setDelEdgeId('');
            setIsEditingGraph(false);
        }
    }, [isEditingGraph]);

    const handleAddEdge = () => {
        const w = parseInt(addEdgeWeight);
        if (addEdgeSource === '' || addEdgeTarget === '') {
            alert(t('graph.graph-visualizer.alerts.select-node', {ns: 'pages'}));
        } else if (isNaN(w) || w <= 0) {
            alert(t('graph.graph-visualizer.alerts.invalid-weight', {ns: 'pages'}));
        } else {
            const edges = data.edges;

            const newEdges = edges.concat({
                id: `edge-${nextEdgeId}`,
                source: addEdgeSource,
                target: addEdgeTarget,
                style: {
                    label: {
                        value: addEdgeWeight,
                        offset: [0, 0],
                    },
                    keyshape: {
                        stroke: colors['edgeNormal'],
                        lineWidth: 1,
                    },
                },
                weight: w,
            });

            const processedEdges = Utils.processEdges(newEdges);

            const newData = {
                nodes: data.nodes,
                edges: processedEdges,
            };

            setNextEdgeId(nextEdgeId + 1);

            setLog(log.concat({
                level: 'debug',
                text: 'Edge ' + data.nodes.find(node => node.id === addEdgeSource).label + ' -> ' + data.nodes.find(node => node.id === addEdgeTarget).label + ' (w=' + w + ') was added.'
            }));

            setHistory({
                boruvka: 0,
                prim: 0,
                kruskal: 0
            });

            setData(newData);
        }
    };

    const handleAddNode = () => {
        if (letters.length <= 0) {
            alert(t('graph.graph-visualizer.alerts.reach-node-maximum', {ns: 'pages'}));
        } else {
            const newData = {
                nodes: [
                    ...data.nodes,
                    {
                        id: `node-${nextNodeId}`,
                        label: letters[0],
                        style: {
                            label: {
                                value: letters[0]
                            }
                        },
                    }
                ],
                edges: data.edges,
            };

            setNextNodeId(nextNodeId + 1);

            setLog(log.concat({
                level: 'debug',
                text: 'Node ' + letters[0] +' was added.'
            }));

            setHistory({
                boruvka: 0,
                prim: 0,
                kruskal: 0
            });

            setData(newData);
        }
    };

    const handleDeleteEdge = () => {
        if (delEdgeId === '') {
            alert(t('graph.graph-visualizer.alerts.invalid-edge', {ns: 'pages'}));
        } else {
            const newEdges = data.edges.filter(edge => edge.id !== delEdgeId);
            const edgeToDel = data.edges.find(edge => edge.id === delEdgeId);
            const newNodes = data.nodes;

            const processedEdges = Utils.processEdges(newEdges);

            setLog(log.concat({
                level: 'debug',
                text: 'Edge ' + data.nodes.find(node => node.id === edgeToDel.source).label + ' -> ' + data.nodes.find(node => node.id === edgeToDel.target).label + ' (w=' + edgeToDel.weight + ') was deleted.'
            }));

            setData({
                nodes: newNodes,
                edges: processedEdges,
            });

            setHistory({
                boruvka: 0,
                prim: 0,
                kruskal: 0
            });

            setDelEdgeId('');
        }
    };

    const handleDeleteNode = () => {
        if (delNodeId === '') {
            alert(t('graph.graph-visualizer.alerts.invalid-node', {ns: 'pages'}));
        } else {
            const newEdges = data.edges.filter(edge => edge.source !== delNodeId && edge.target !== delNodeId);
            const newNodes = data.nodes.filter(node => node.id !== delNodeId);
            const nodeToDel = data.nodes.find(node => node.id === delNodeId);

            setLog(log.concat({
                level: 'debug',
                text: 'Node ' + nodeToDel.label + ' was deleted.'
            }));

            setData({
                nodes: newNodes,
                edges: newEdges,
            });

            setHistory({
                boruvka: 0,
                prim: 0,
                kruskal: 0
            });

            setIsEditingGraph(true);
        }
    };

    const handleClearPath = () => {
        const newEdges = data.edges.map((edge, index) => ({
            ...edge,
            style: {
                label: {
                    value: edge.weight,
                    offset: [0, 0],
                },
                keyshape: {
                    stroke: colors['edgeNormal'],
                    lineWidth: 1,
                },
            },
        }));
        const newNodes = [...data.nodes];

        setLog(log.concat({
            level: 'debug',
            text: 'Path cleared.'
        }));

        setData({
            nodes: newNodes,
            edges: newEdges,
        });

    };
    const handleHighlightEdges = (idArray, type) => {
        let edgesUpdated = data.edges;

        idArray.forEach((id) => {
            edgesUpdated = edgesUpdated.map((edge, index) => {
                if (edge.id === id) {
                    return ({
                        ...edge,
                        style: {
                            label: {
                                value: edge.weight,
                                offset: [0, 0],
                            },
                            keyshape: {
                                stroke: colors[type],
                                lineWidth: 1,
                            },
                        },
                    });
                } else {
                    return ({...edge});
                }
            });
        });

        const nodesUpdated = [...data.nodes];

        setData({
            nodes: nodesUpdated,
            edges: edgesUpdated,
        });
    };

    const handleHighlightEdgesInQueue = (time) => {
        let edgesUpdated = data.edges;

        time.forEach((step) => {
            edgesUpdated = edgesUpdated.map((edge, index) => {
                if (step.id === edge.id) {
                    return ({
                        ...edge,
                        style: {
                            label: {
                                value: edge.weight,
                                offset: [0, 0],
                            },
                            keyshape: {
                                stroke: colors[step.type],
                                lineWidth: 1,
                            },
                        },
                    });
                } else {
                    return ({...edge});
                }
            });
        });

        const nodesUpdated = [...data.nodes];

        setData({
            nodes: nodesUpdated,
            edges: edgesUpdated,
        });
    };

    const handleClear = () => {
        setData({
            nodes: [],
            edges: [],
        });

        setLog(log.concat({
            level: 'debug',
            text: 'All nodes was deleted.'
        }));

        setHistory({
            boruvka: 0,
            prim: 0,
            kruskal: 0
        });

        setIsEditingGraph(true);
    };

    const handleMock = () => {
        const mockData = getMock();

        setLog(log.concat({
            level: 'debug',
            text: 'Mock data inserted.'
        }));

        setHistory({
            boruvka: 0,
            prim: 0,
            kruskal: 0
        });

        setIsEditingGraph(true);

        setLetters([
            'G', 'H', 'I', 'J',
            'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
            'U', 'V', 'W', 'X', 'Y', 'Z'
        ]);

        setData(mockData);
    };


    return (
        <Stack direction={'column'}>
            <Heading p={4}>{t('common:modules.graph.mst.title')}</Heading>

            <Grid
                templateAreas={{md: `
                            "left right"
                        `, base: `
                            "left"
                            "right"
                        `}}
                gridTemplateRows={'auto 1fr'}
                gridTemplateColumns={{md: '60% 1fr', base: 'auto 1fr'}}
                gap={{md: '2', base: '1'}}
            >
                <GridItem area={'left'}>
                    <Stack direction={'column'}>
                        <Card>
                            <CardHeader>
                                <Heading size='md'>{t('graph.graph-visualizer.graph', {ns: 'pages'})}</Heading>
                            </CardHeader>
                            <CardBody>
                                <GraphRenderer data={data} layoutType={style}/>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <Heading size='md'>{t('graph.graph-visualizer.log', {ns: 'pages'})}</Heading>
                            </CardHeader>
                            <CardBody>
                                <LogRenderer data={log} />
                            </CardBody>
                        </Card>
                    </Stack>
                </GridItem>
                <GridItem area={'right'}>
                    <Stack direction={'column'}>
                        <Card>
                            <CardHeader>
                                <Heading size='md'>{t('graph.graph-visualizer.graph-editing.title', {ns: 'pages'})}</Heading>
                            </CardHeader>
                            <CardBody>
                                <Stack divider={<StackDivider />} spacing='4'>
                                    <Box>
                                        <FormLabel>
                                            <Heading size='sm'>
                                                {t('pages:graph.graph-visualizer.graph-editing.nodes.title')}
                                            </Heading>
                                        </FormLabel>
                                        <FormControl>
                                            <HStack>
                                                <Button
                                                    colorScheme='teal'
                                                    size='md'
                                                    fontSize={'sm'}
                                                    isDisabled={disableAll}
                                                    onClick={() => handleAddNode()}
                                                >
                                                    {t('graph.graph-visualizer.graph-editing.nodes.add', {ns: 'pages'})}
                                                </Button>
                                                <Select fontSize={'sm'} value={delNodeId} isDisabled={disableAll} onChange={(event) => {setDelNodeId(event.target.value)}}>
                                                    <option value={''} disabled={true}>{t('graph.graph-visualizer.graph-editing.nodes.select-node', {ns: 'pages'})}</option>
                                                    {data.nodes.map((node, index) => (
                                                        <option value={node.id} key={index}>{node.label}</option>
                                                    ))}
                                                </Select>
                                                <Button
                                                    colorScheme='teal'
                                                    size='md'
                                                    isDisabled={disableAll}
                                                    fontSize={'sm'}
                                                    onClick={() => handleDeleteNode()}
                                                >
                                                    {t('graph.graph-visualizer.graph-editing.nodes.delete', {ns: 'pages'})}
                                                </Button>
                                            </HStack>
                                        </FormControl>
                                    </Box>
                                    <Box>
                                        <FormControl >
                                            <FormLabel>
                                                <Heading size='sm'>
                                                    {t('pages:graph.graph-visualizer.graph-editing.edges.title')}
                                                </Heading>
                                            </FormLabel>
                                            <Stack direction={'column'}>
                                                <HStack>
                                                    <Select fontSize={'sm'} value={addEdgeSource} isDisabled={disableAll} onChange={(event) => {setAddEdgeSource(event.target.value)}}>
                                                        <option value={''} disabled={true}>{t('graph.graph-visualizer.graph-editing.edges.select-from', {ns: 'pages'})}</option>
                                                        {data.nodes.map((node, index) => (
                                                            <option value={node.id} key={index}>{node.label}</option>
                                                        ))}
                                                    </Select>
                                                    <Select fontSize={'sm'} value={addEdgeTarget} isDisabled={disableAll} onChange={(event) => {setAddEdgeTarget(event.target.value)}}>
                                                        <option value={''} disabled={true}>{t('graph.graph-visualizer.graph-editing.edges.select-to', {ns: 'pages'})}</option>
                                                        {data.nodes.map((node, index) => (
                                                            <option value={node.id} key={index}>{node.label}</option>
                                                        ))}
                                                    </Select>
                                                    <Input fontSize={'sm'} placeholder={t('graph.graph-visualizer.graph-editing.edges.weight', {ns: 'pages'})} isDisabled={disableAll} value={addEdgeWeight} onChange={(event) => {setAddEdgeWeight(event.target.value)}} />
                                                    <Button
                                                        colorScheme='teal'
                                                        size='md'
                                                        fontSize={'sm'}
                                                        isDisabled={disableAll}
                                                        onClick={() => handleAddEdge()}
                                                    >
                                                        {t('graph.graph-visualizer.graph-editing.edges.add', {ns: 'pages'})}
                                                    </Button>
                                                </HStack>
                                                <HStack>
                                                    <Select fontSize={'sm'} value={delEdgeId} isDisabled={disableAll} onChange={(event) => {setDelEdgeId(event.target.value)}}>
                                                        <option disabled={true} value={''}>{t('graph.graph-visualizer.graph-editing.edges.select-edge', {ns: 'pages'})}</option>
                                                        {data.edges.map((edge, index) => (
                                                            <option value={edge.id} key={index}>{
                                                                data.nodes.find((node) => node.id === edge.source).label
                                                                + ' -> ' +
                                                                data.nodes.find((node) => node.id === edge.target).label
                                                                + ' (w=' + edge.weight + ')'
                                                            }</option>
                                                        ))}
                                                    </Select>
                                                    <Button
                                                        colorScheme='teal'
                                                        size='md'
                                                        fontSize={'sm'}
                                                        isDisabled={disableAll}
                                                        onClick={() => handleDeleteEdge()}
                                                    >
                                                        {t('graph.graph-visualizer.graph-editing.edges.delete', {ns: 'pages'})}
                                                    </Button>
                                                </HStack>
                                            </Stack>
                                        </FormControl>
                                    </Box>
                                    <Box>
                                        <FormControl>
                                            <FormLabel>
                                                <Heading size='sm'>
                                                    {t('pages:graph.shortest-path.board')}
                                                </Heading>
                                            </FormLabel>
                                            <HStack>
                                                <Button
                                                    size='md'
                                                    fontSize={'sm'}
                                                    isDisabled={disableAll}
                                                    colorScheme='teal'
                                                    onClick={() => handleMock()}
                                                >
                                                    {t('pages:graph.shortest-path.mock')}
                                                </Button>
                                                <Button
                                                    colorScheme='teal'
                                                    size='md'
                                                    fontSize={'sm'}
                                                    isDisabled={disableAll}
                                                    onClick={() => handleClear()}
                                                >
                                                    {t('graph.graph-visualizer.graph-editing.nodes.clear-all', {ns: 'pages'})}
                                                </Button>
                                                <Select fontSize={'sm'} value={style} onChange={(event) => setStyle(event.target.value)}>
                                                    <option value={'force'}>{t('graph.graph-visualizer.graph-editing.style.force', {ns: 'pages'})}</option>
                                                    <option value={'gForce'}>{t('graph.graph-visualizer.graph-editing.style.g-force', {ns: 'pages'})}</option>
                                                    <option value={'graphin-force'}>{t('graph.graph-visualizer.graph-editing.style.graphin-force', {ns: 'pages'})}</option>
                                                    <option value={'circular'}>{t('graph.graph-visualizer.graph-editing.style.circular', {ns: 'pages'})}</option>
                                                    <option value={'radial'}>{t('graph.graph-visualizer.graph-editing.style.radial', {ns: 'pages'})}</option>
                                                    <option value={'concentric'}>{t('graph.graph-visualizer.graph-editing.style.concentric', {ns: 'pages'})}</option>
                                                </Select>
                                            </HStack>
                                        </FormControl>
                                    </Box>
                                </Stack>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <Heading size='md'>
                                    {t('graph.graph-visualizer.cpanel.mst', {ns: 'pages'})}
                                </Heading>
                            </CardHeader>
                            <CardBody>
                                <MSTControlPanel
                                    data={data}
                                    disableAll={disableAll}
                                    setDisableAll={setDisableAll}
                                    handleHighlightEdges={handleHighlightEdges}
                                    handleClearPath={handleClearPath}
                                    handleHighlightEdgesInQueue={handleHighlightEdgesInQueue}
                                    log={log}
                                    setLog={setLog}
                                    history={history}
                                    setHistory={setHistory}
                                    algorithm={algorithm}
                                    setAlgorithm={setAlgorithm}
                                />
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <Heading size='md'>
                                    {t('pages:graph.shortest-path.comparing')}
                                </Heading>
                            </CardHeader>
                            <CardBody>
                                <Stack direction={'column'}>
                                    <Stack>
                                        <Heading fontSize={'sm'}>{t('common:modules.graph.mst.algorithms.boruvka')}</Heading>
                                        <Progress colorScheme={'teal'} size={'sm'} value={history.boruvka/Math.max(history.boruvka, history.prim, history.kruskal) * 100} isIndeterminate={disableAll && algorithm === 'boruvka'} />
                                    </Stack>
                                    <Stack>
                                        <Heading fontSize={'sm'}>{t('common:modules.graph.mst.algorithms.kruskal')}</Heading>
                                        <Progress colorScheme={'teal'} size={'sm'} value={history.kruskal/Math.max(history.boruvka, history.prim, history.kruskal) * 100} isIndeterminate={disableAll && algorithm === 'kruskal'} />
                                    </Stack>
                                    <Stack>
                                        <Heading fontSize={'sm'}>{t('common:modules.graph.mst.algorithms.prim')}</Heading>
                                        <Progress colorScheme={'teal'} size={'sm'} value={history.prim/Math.max(history.boruvka, history.prim, history.kruskal) * 100} isIndeterminate={disableAll && algorithm === 'prim'} />
                                    </Stack>
                                </Stack>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <Heading size='md'>
                                    {t('pages:graph.shortest-path.complexity')}
                                </Heading>
                            </CardHeader>
                            <CardBody>
                                <Stack direction={'column'}>
                                    <Flex>
                                        <Heading fontSize={'sm'}>{t('common:modules.graph.mst.algorithms.boruvka')}</Heading>
                                        <Spacer />
                                        <MathJax>
                                            <Text fontSize={'sm'}>{`\\(O(mn)\\)`}</Text>
                                        </MathJax>
                                    </Flex>
                                    <Flex>
                                        <Heading fontSize={'sm'}>{t('common:modules.graph.mst.algorithms.kruskal')}</Heading>
                                        <Spacer />
                                        <MathJax>
                                            <Text fontSize={'sm'}>{`\\(O(n \\log n)\\)`}</Text>
                                        </MathJax>
                                    </Flex>
                                    <Flex>
                                        <Heading fontSize={'sm'}>{t('common:modules.graph.mst.algorithms.prim')}</Heading>
                                        <Spacer />
                                        <MathJax>
                                            <Text fontSize={'sm'}>{`\\(O(n \\log m)\\)`}</Text>
                                        </MathJax>
                                    </Flex>
                                </Stack>
                            </CardBody>
                        </Card>
                    </Stack>
                </GridItem>
            </Grid>
        </Stack>
    );
};

export default GraphMSTPathPage;