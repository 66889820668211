import React, {useEffect, useState} from "react";

import Node from "../../../components/widgets/maze/Node";

// Algorithms
import { dijkstra } from "../../../algorithms/maze/path-finding/dijkstra";
import { aStar } from "../../../algorithms/maze/path-finding/a-star";

// Utils
import { generateMaze } from "../../../utils/maze/path-finding/generateMaze";
import {
    highlightCurrentNode,
    unHighlightCurrentNode,
} from "../../../utils/maze/path-finding/highlightNodes";

// Stylesheets
import "./index.scss";

// UI
import {
    Box,
    Button,
    Flex,
    Select,
    Spacer,
    Stack,
    Card,
    Heading,
    CardHeader,
    StackDivider,
    CardBody,
    Grid,
    GridItem,
    FormLabel,
    FormControl,
    Text,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { MathJax } from "better-react-mathjax";

// Node States
const BEGIN_NODE_STATE = 1;
const END_NODE_STATE = 2;
const WALL_NODE_STATE = 3;

// Grid Dimensions
const ROWS = 45;
const COLUMNS = 45;


const MazePathFindingPage = () => {
    // States
    const [grid, setGrid] = useState([]);
    const [modifyingNodeState, setModifyingNodeState] = useState(0);
    const [beginNodeRow, setBeginNodeRow] = useState(2);
    const [beginNodeColumn, setBeginNodeColumn] = useState(2);
    const [endNodeRow, setEndNodeRow] = useState(ROWS - 3);
    const [endNodeColumn, setEndNodeColumn] = useState(COLUMNS - 3);

    const [disableGenerateMazeButton, setDisableGenerateMazeButton] = useState(false);
    const [disableNodesButton, setDisableNodesButton] = useState(false);
    const [disableClearMazeButton, setDisableClearMazeButton] = useState(false);
    const [disableClearPathButton, setDisableClearPathButton] = useState(false);
    const [disableAlgoDropdown, setDisableAlgoDropdown] = useState(false);
    const [disablePerformButton, setDisablePerformButton] = useState(false);

    const [nodeHighlighted, setNodeHighlighted] = useState(true);

    const [algorithm, setAlgorithm] = useState(0);
    const [speed, setSpeed] = useState(15);

    const { t } = useTranslation('widgets', 'common');

    const createNode = (row, column) => {
        return {
            row,
            column,
            isBegin: row === beginNodeRow && column === beginNodeColumn,
            isEnd: row === endNodeRow && column === endNodeColumn,
            distance: Infinity,
            isVisited: false,
            isWall: false,
            previousNode: null,
            cost: {
                F: Infinity,
                G: Infinity,
                H: Infinity,
            },
        };
    }

    const setupGrid = () => {
        const gridBox = document.getElementById('grid');
        gridBox.style.setProperty('--p-grid-rows', ROWS);
        gridBox.style.setProperty('--p-grid-cols', COLUMNS);

        const newGrid = new Array(COLUMNS);

        for (let row = 0; row < ROWS; row++) {
            newGrid[row] = new Array(ROWS);
            for (let column = 0; column < COLUMNS; column++) {
                newGrid[row][column] = createNode(row, column);
            }
        }

        setGrid(newGrid);
    }

    // Components mounted
    useEffect(() => {
        const gridBox = document.getElementById('grid');
        gridBox.style.setProperty('--p-grid-rows', ROWS);
        gridBox.style.setProperty('--p-grid-cols', COLUMNS);

        const newGrid = new Array(COLUMNS);

        for (let row = 0; row < ROWS; row++) {
            newGrid[row] = new Array(ROWS);
            for (let column = 0; column < COLUMNS; column++) {
                newGrid[row][column] = {
                    row,
                    column,
                    isBegin: row === 2 && column === 2,
                    isEnd: row === ROWS - 3 && column === ROWS -3,
                    distance: Infinity,
                    isVisited: false,
                    isWall: false,
                    previousNode: null,
                    cost: {
                        F: Infinity,
                        G: Infinity,
                        H: Infinity,
                    },
                };
            }
        }

        setGrid(newGrid);
        setDisableClearMazeButton(true);
        setDisableClearPathButton(true);
    }, []);

    const handleHighlightCurrentNode = (row, column) => {
        if (nodeHighlighted) {
            highlightCurrentNode(row, column);
        }
    }

    const handleUnHighlightCurrentNode = (row, column) => {
        if (nodeHighlighted) {
            unHighlightCurrentNode(row, column);
        }
    }

    const handleClearMaze = () => {
        setupGrid();
        const newGrid = [...grid];
        for (let i = 0; i < newGrid.length; i++) {
            for (let j = 0; j < newGrid[0].length; j++) {
                const node = newGrid[i][j];
                document
                    .getElementById(`node-${node.row}-${node.column}`)
                    .classList.remove('node-visited');
                document
                    .getElementById(`node-${node.row}-${node.column}`)
                    .classList.remove("node-shortest-path");
            }
        }
        setDisableGenerateMazeButton(false);
        setDisableNodesButton(false);
        setDisableClearPathButton(true);
        setDisableClearMazeButton(true);
        setDisableAlgoDropdown(false);
        setDisablePerformButton(false);
        setNodeHighlighted(true);
    }

    const handleClearPath = () => {
        const temp = [...grid];
        for (let i = 0; i < temp.length; i++) {
            for (let j = 0; j < temp[i].length; j++) {
                let node = temp[i][j];
                if (node.isVisited) {
                    node.isVisited = false;
                    document
                        .getElementById(`node-${node.row}-${node.column}`)
                        .classList.remove("node-visited");
                    document
                        .getElementById(`node-${node.row}-${node.column}`)
                        .classList.remove("node-shortest-path");
                }
            }
        }
        setDisableGenerateMazeButton(true);
        setDisableNodesButton(true);
        setDisableAlgoDropdown(false);
        setDisablePerformButton(false);
        setNodeHighlighted(true);
    }

    const handleNodeOperation = (row, column, nodeState) => {
        if (nodeState === BEGIN_NODE_STATE) {
            setBeginNode(row, column)
        } else if (nodeState === END_NODE_STATE) {
            setEndNode(row, column)
        } else if (nodeState === WALL_NODE_STATE) {
            setWallNode(row, column)
        }
    }

    const setBeginNode = (row, column) => {
        const newGrid = [...grid];
        const currentNode = newGrid[beginNodeRow][beginNodeColumn];
        const newNode = newGrid[row][column];

        if (!newNode.isWall && !newNode.isEnd) {
            currentNode.isBegin = false;
            newNode.isBegin = true;
            setBeginNodeRow(row);
            setBeginNodeColumn(column);
            setGrid(newGrid);
        }
    }

    const setEndNode = (row, column) => {
        const newGrid = [...grid];
        const currentNode = newGrid[endNodeRow][endNodeColumn];
        const newNode = newGrid[row][column];

        if (!newNode.isWall && !newNode.isBegin) {
            currentNode.isEnd = false;
            newNode.isEnd = true;
            setEndNodeRow(row);
            setEndNodeColumn(column);
            setGrid(newGrid);
        }
    }

    const setWallNode = (row, column) => {
        const newGrid = [...grid];
        const currentNode = newGrid[row][column];
        if (!currentNode.isBegin && !currentNode.isEnd) {
            currentNode.isWall = !currentNode.isWall;
            setGrid(newGrid);
        }
    }

    const handleGenerateMaze = () => {
        setDisableGenerateMazeButton(true);
        setDisableClearPathButton(true);
        setDisableClearMazeButton(true);
        const newGrid = generateMaze(grid);
        setGrid(newGrid);
        setDisableClearMazeButton(false);
    }

    const handleNodeStateModifying = (state) => {
        setModifyingNodeState(state);
    }

    const handleAlgorithmChangeAndDoVisualization = () => {
        if (algorithm !== 0 && !isNaN(algorithm)) algorithmVisualizer(algorithm);
        else {
            alert(t('maze.path-finding.alert-select-algo-first'));
        }
    }

    const algorithmVisualizer = (algorithm) => {
        setDisableGenerateMazeButton(true);
        setDisableNodesButton(true);
        setDisableAlgoDropdown(true);
        setDisableClearPathButton(true);
        setDisableClearMazeButton(true);
        setDisablePerformButton(true);
        setModifyingNodeState(0);

        const beginNode = grid[beginNodeRow][beginNodeColumn];
        const endNode = grid[endNodeRow][endNodeColumn];

        let visitedNodes;
        let shortestPathNodes;

        switch (algorithm) {
            case 0:
                // No algorithm selected
                alert(t('maze.path-finding.alert-select-algo-first'));
                setDisableGenerateMazeButton(false);
                setDisableNodesButton(false);
                return;

            case 1:
                // Dijkstra
                [visitedNodes, shortestPathNodes] = dijkstra(grid, beginNode, endNode);
                break;

            case 2:
                // Astar
                [visitedNodes, shortestPathNodes] = aStar(grid, beginNode, endNode);
                break;
            default:
                return;
        }

        animatePaths(visitedNodes, shortestPathNodes);
    }

    const animatePaths = (visitedNodes = [], shortestPathNodes = []) => {
        setDisableGenerateMazeButton(true);
        setNodeHighlighted(false);

        for (let i = 0; i <= visitedNodes.length; i++) {
            if (i === visitedNodes.length) {
                setTimeout(() => {
                    animateShortestPath(shortestPathNodes);
                }, speed * i);

                return;
            }

            setTimeout(() => {
                const node = visitedNodes[i];
                if (!node.isBegin && !node.isEnd && !node.isWall) {
                    document.getElementById(`node-${node.row}-${node.column}`).className = "node node-visited";
                }
            }, speed * i);
        }
    }

    const animateShortestPath = (shortestPathNodes = []) => {
        for (let i = 0; i < shortestPathNodes.length; i++) {
            setTimeout(() => {
                const node = shortestPathNodes[i];
                if (!node.isBegin && !node.isEnd && !node.isWall) {
                    document.getElementById(`node-${node.row}-${node.column}`).className = "node node-shortest-path";
                }
                if (node.isEnd) {
                    setTimeout(() => {
                        setDisableClearMazeButton(false);
                        setDisableClearPathButton(false);
                    }, 1000);
                }
            }, speed * 1.4 * i);
        }
    }

    return (
        <Stack direction={'column'}>
            <Heading p={4}>{t('common:modules.misc.maze-path-finding.title')}</Heading>
            <Grid
                templateAreas={{md: `
                           "left right"
                       `, base: `
                           "left"
                           "right"
                       `}}
                gridTemplateRows={'auto 1fr'}
                gridTemplateColumns={{md: '50% 1fr', base: 'auto 1fr'}}
                gap={{md: '2', base: '1'}}
            >
                <GridItem area={'left'}>
                    <Stack direction={'column'}>
                        <Card>
                            <CardHeader>
                                <Heading size='md'>{t('pages:maze.path-finding.board')}</Heading>
                            </CardHeader>
                            <CardBody>
                                <Box boxSize='100%'>
                                    <Box
                                        id="grid"
                                        className="grid"
                                    >
                                        {grid.map((node) => {
                                            return node.map((cell) => {
                                                const { row, column, isBegin, isEnd, isWall } = cell;
                                                return (
                                                    <Node
                                                        key={`${row}-${column}`}
                                                        row={row}
                                                        column={column}
                                                        isEnd={isEnd}
                                                        isBegin={isBegin}
                                                        isWall={isWall}
                                                        onNodeClick={(row, column) =>
                                                            handleNodeOperation(
                                                                row,
                                                                column,
                                                                modifyingNodeState
                                                            )
                                                    }
                                                        onNodeOver={(row, column) =>
                                                            handleHighlightCurrentNode(row, column)
                                                    }
                                                        onNodeOut={(row, column) =>
                                                            handleUnHighlightCurrentNode(row, column)
                                                    }
                                                    />
                                                );
                                            });
                                        })}
                                    </Box>
                                </Box>
                            </CardBody>
                        </Card>
                    </Stack>
                </GridItem>
                <GridItem area={'right'}>
                    <Stack direction={'column'}>
                        <Card>
                            <CardHeader>
                                <Heading size='md'> {t('maze.path-finding.ctrlp-title')} </Heading>
                            </CardHeader>
                            <CardBody>
                                <Stack divider={<StackDivider />} spacing='4'>
                                    <Box>
                                        <FormLabel>
                                            <Heading size='sm'>
                                                {t('maze.path-finding.ctrlp-nodes')}
                                            </Heading>
                                        </FormLabel>
                                        <FormControl>
                                            <Stack>
                                                <Button
                                                    isDisabled={disableNodesButton}
                                                    colorScheme='teal'
                                                    size='md'
                                                    fontSize={'sm'}
                                                    onClick={() => handleNodeStateModifying(BEGIN_NODE_STATE)}
                                                >
                                                    {t('maze.path-finding.ctrlp-button-src')}
                                                </Button>
                                                <Button
                                                    isDisabled={disableNodesButton}
                                                    colorScheme='teal'
                                                    size='md'
                                                    fontSize={'sm'}
                                                    onClick={() => handleNodeStateModifying(END_NODE_STATE)}
                                                >
                                                    {t('maze.path-finding.ctrlp-button-dest')}
                                                </Button>
                                                <Button
                                                    isDisabled={disableNodesButton}
                                                    colorScheme='teal'
                                                    size='md'
                                                    fontSize={'sm'}
                                                    onClick={() => handleNodeStateModifying(WALL_NODE_STATE)}
                                                >
                                                    {t('maze.path-finding.ctrlp-button-wall')}
                                                </Button>
                                            </Stack>
                                        </FormControl>
                                    </Box>
                                    <Box>
                                        <FormLabel>
                                            <Heading size='sm'>
                                                {t('maze.path-finding.ctrlp-board')}
                                            </Heading>
                                        </FormLabel>
                                        <FormControl>
                                            <Stack>
                                                <Button
                                                    isDisabled={disableGenerateMazeButton}
                                                    colorScheme='teal'
                                                    size='md'
                                                    fontSize={'sm'}
                                                    onClick={() => handleGenerateMaze(grid)}
                                                >
                                                    {t('maze.path-finding.ctrlp-button-gmaze')}
                                                </Button>
                                                <Button
                                                    isDisabled={disableClearMazeButton}
                                                    colorScheme='teal'
                                                    size='md'
                                                    fontSize={'sm'}
                                                    onClick={() => handleClearMaze()}
                                                >
                                                    {t('maze.path-finding.ctrlp-button-cmaze')}
                                                </Button>
                                                <Button
                                                    isDisabled={disableClearPathButton}
                                                    colorScheme='teal'
                                                    size='md'
                                                    fontSize={'sm'}
                                                    onClick={() => handleClearPath()}
                                                >
                                                    {t('maze.path-finding.ctrlp-button-cpath')}
                                                </Button>
                                            </Stack>
                                        </FormControl>
                                    </Box>
                                    <Box>
                                        <FormLabel>
                                            <Heading size='sm'>
                                                {t('maze.path-finding.ctrlp-speed')}
                                            </Heading>
                                        </FormLabel>
                                        <FormControl>
                                            <Select fontSize={'sm'} placeholder={t('maze.path-finding.ctrlp-dropdown-speed-select')} disabled={disableAlgoDropdown} id="visualizationSpeedText" defaultValue="15" onChange={(event) => {setSpeed(parseInt(event.target.value))}}>
                                                <option value="5">{t('maze.path-finding.ctrlp-dropdown-speed-veryfast')}</option>
                                                <option value="10">{t('maze.path-finding.ctrlp-dropdown-speed-fast')}</option>
                                                <option value="15">{t('maze.path-finding.ctrlp-dropdown-speed-normal')}</option>
                                                <option value="20">{t('maze.path-finding.ctrlp-dropdown-speed-slow')}</option>
                                                <option value="25">{t('maze.path-finding.ctrlp-dropdown-speed-veryslow')}</option>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box>
                                        <FormLabel>
                                            <Heading size='sm'>
                                                {t('maze.path-finding.ctrlp-algorithm')}
                                            </Heading>
                                        </FormLabel>
                                        <FormControl>
                                            <Select fontSize={'sm'} placeholder={t('maze.path-finding.ctrlp-dropdown-algo-select')} disabled={disableAlgoDropdown} id="pathFindingAlgoDropDown" defaultValue="0" onChange={(event) => {setAlgorithm(parseInt(event.target.value))}}>
                                                <option value="1">{t('common:modules.misc.maze-path-finding.algorithms.dijkstra')}</option>
                                                <option value="2">{t('common:modules.misc.maze-path-finding.algorithms.a-star')}</option>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box>
                                        <FormLabel>
                                            <Heading size='sm'>
                                                {t('maze.path-finding.ctrlp-visualization')}
                                            </Heading>
                                        </FormLabel>
                                        <FormControl>
                                            <Stack>
                                                <Button
                                                    isDisabled={disablePerformButton}
                                                    colorScheme='teal'
                                                    size='md'
                                                    fontSize={'sm'}
                                                    onClick={() => handleAlgorithmChangeAndDoVisualization()}
                                                >
                                                    {t('maze.path-finding.ctrlp-button-visualize')}
                                                </Button>
                                            </Stack>
                                        </FormControl>
                                    </Box>
                                </Stack>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <Heading size='md'>{t('pages:maze.path-finding.complexity')}</Heading>
                            </CardHeader>
                            <CardBody>
                                <Stack direction={'column'}>
                                    <Flex>
                                        <Heading fontSize={'sm'}>{t('common:modules.graph.shortest-path.algorithms.dijkstra')}</Heading>
                                        <Spacer />
                                        <MathJax>
                                            <Text fontSize={'sm'}>{`\\(O(m \\log m)\\)`}</Text>
                                        </MathJax>
                                    </Flex>
                                    <Flex>
                                        <Heading fontSize={'sm'}>{t('common:modules.graph.shortest-path.algorithms.bellman-ford')}</Heading>
                                        <Spacer />
                                        <MathJax>
                                            <Text fontSize={'sm'}>{`\\(O(nm)\\)`}</Text>
                                        </MathJax>
                                    </Flex>
                                    <Flex>
                                        <Heading fontSize={'sm'}>{t('common:modules.graph.shortest-path.algorithms.floyd-warshall')}</Heading>
                                        <Spacer />
                                        <MathJax>
                                            <Text fontSize={'sm'}>{`\\(O(n^3)\\)`}</Text>
                                        </MathJax>
                                    </Flex>
                                    <Flex>
                                        <Heading fontSize={'sm'}>{t('common:modules.graph.shortest-path.algorithms.johnson')}</Heading>
                                        <Spacer />
                                        <MathJax>
                                            <Text fontSize={'sm'}>{`\\(O(nm \\log m)\\)`}</Text>
                                        </MathJax>
                                    </Flex>
                                </Stack>
                            </CardBody>
                        </Card>
                    </Stack>
                </GridItem>
            </Grid>
        </Stack>
    );
}

export default MazePathFindingPage;