import { UnionFind } from "../../../utils/graph/mst/UnionFind";

export const boruvka = (data, speed, handleHighlightEdgesInQueue, setDisableAll, setLog, log, history, setHistory) => {
    let comparing=0;
    const edges = data.edges;
    const nodes = data.nodes;
    const nodeIds = nodes.map(node => node.id);
    const unionFind = new UnionFind(nodeIds);
    let f = new Set();

    const n = data.nodes.length;
    let dist = new Array(n).fill().map(() => new Array(n).fill(Infinity));
    let edge = new Array(n).fill().map(() => new Array(n).fill(-1));
    let join = new Array(n).fill(-1);
    let notJoin = new Array(n).fill(-1);
    let mindis = new Array(n).fill(Infinity);
    let mindisid=new Array(n).fill(-1);

    //begin vis
    let timeStep = 0;
    setDisableAll(true);

    let edgeRenderQueue = [];
    /* edgeRenderQueue: [timeStep: [step: {id, type}, ...], ...] */

    let logList = log;

    logList.push({
        level: 'debug',
        text: 'Start running Boruvka...'
    });


    const addStep = (id, type) => {
        if (edgeRenderQueue.length === timeStep) {
            /* add timeStep */
            const time = [{
                id: id,
                type: type
            }];
            edgeRenderQueue.push(time);
        } else {
            /* add step to timeStep */
            const time = edgeRenderQueue[edgeRenderQueue.length - 1];
            edgeRenderQueue[edgeRenderQueue.length - 1] = time.concat({
                id: id,
                type: type
            });
        }
    }
    //end vis
    const getNodeNumById = (id) => {
        for (let i = 0; i < data.nodes.length; i++) {
            if (data.nodes[i].id === id) {
                return i;
            }
        }

        return -1;
    }
    const getNodeIdByNum = (num) => {
        return data.nodes.find(node => getNodeNumById(node.id) === num).id;
    }

    for (let i = 0; i < edges.length; i++) {
        //begin vis
        addStep(edges[i].id, 'edgeVisited');
        timeStep++;

        logList.push({
            level: 'info',
            text: 'Edge '
                + nodes.find(node => node.id === edges[i].source).label
                + ' -> '
                + nodes.find(node => node.id === edges[i].target).label
                + ' (w='
                + edges[i].weight
                +') visited.'
        });
        //end vis

        dist[getNodeNumById(edges[i].source)][getNodeNumById(edges[i].target)] = edges[i].weight;

        edge[getNodeNumById(edges[i].source)][getNodeNumById(edges[i].target)] = edges[i].id;
        //begin vis

        /* handle array update */
        if(dist[getNodeNumById(edges[i].source)][getNodeNumById(edges[i].target)] <dist[getNodeNumById(edges[i].target)][getNodeNumById(edges[i].source)] ){
            dist[getNodeNumById(edges[i].target)][getNodeNumById(edges[i].source)]=dist[getNodeNumById(edges[i].source)][getNodeNumById(edges[i].target)];
            edge[getNodeNumById(edges[i].target)][getNodeNumById(edges[i].source)] = edges[i].id;
        }else{
            dist[getNodeNumById(edges[i].source)][getNodeNumById(edges[i].target)]=dist[getNodeNumById(edges[i].target)][getNodeNumById(edges[i].source)];
            edge[getNodeNumById(edges[i].source)][getNodeNumById(edges[i].target)] = edge[getNodeNumById(edges[i].target)][getNodeNumById(edges[i].source)];
        }
        //end vis
    }
    for (let i = 0; i < data.nodes.length; i++) {
        notJoin[i] = getNodeNumById(data.nodes[i].id);
    }
    let minnum=-1;
    let min=Infinity;
    let pre=-1;
    let num=-1;
    let a=-1;

    while(num<n-1){
        num++;
        for(let j=0;j<notJoin.length;j++){
            if(notJoin[j]!==-1){
                a=j;
                break;
            }
        }
        join[num]=notJoin[a];
        notJoin[a]=-1;

        for(let i=0;i<n;i++){
            comparing++;
            if(notJoin[i]!==-1){
                mindis[i]=dist[join[num]][notJoin[i]];
                mindisid[i]=edge[join[num]][notJoin[i]];
            }
            else
                mindis[i]=Infinity;
        }
        for(let i=0;i<mindis.length;i++){
            comparing++;
            if(mindis[i]<min){
                min=mindis[i];
                minnum=i;
            }
        }
        if(minnum!==-1){
            f = f.add(mindisid[minnum]);
        }
        while(num<n-1&&minnum!==-1){
            pre=minnum;
            num++;
            join[num]=notJoin[minnum];
            notJoin[minnum]=-1;
            min=Infinity;
            minnum=-1;
            for(let i=0;i<n;i++){
                comparing++;
                if(notJoin[i]!==-1){
                    if(dist[join[num]][notJoin[i]]<mindis[i]){
                        mindis[i]=dist[join[num]][notJoin[i]];
                        mindisid[i]=edge[join[num]][notJoin[i]];
                    }
                }
                else
                    mindis[i]=Infinity;
            }
            for(let i=0;i<mindis.length;i++){
                comparing++;
                if(mindis[i]<min){
                    min=mindis[i];
                    minnum=i;
                }
            }
            if(minnum!==-1){
                f = f.add(mindisid[minnum]);
            }
        }
    }

    let MST = [];
    for (const item of f) {
        MST.push(item);
    }

    //begin vis
    MST.forEach(id => {
        addStep(id, 'edgeMST');
    });

    logList.push({
        level: 'warning',
        text: 'Total time steps: ' + edgeRenderQueue.length
    });

    setHistory({
        ...history,
        boruvka: (comparing)
    });

    logList.push({
        level: 'debug',
        text: 'Boruvka completed. Start rendering...'
    });
    
    /* handle render */

    for (let i = 0; i < edgeRenderQueue.length; i++) {
        setTimeout(() => {
            handleHighlightEdgesInQueue(edgeRenderQueue[i]);
        }, speed * i);
    }

    timeStep++;

    setTimeout(() => {
        setDisableAll(false);
    }, speed * timeStep);
    //end vis
};

const getEdgeId = (data, from, to) => {
    const edge = data.edges.find(edge => (edge.source === from && edge.target === to));
    if (edge === undefined) {
        return '';
    } else {
        return edge.id;
    }
};