export const getMock = () => {
    const colors = {
        'primaryColor': '#38B2AC',
        'nodeSource': '#F56565',
        'nodeTarget': '#4299E1',
        'edgeVisited': '#38B2AC',
        'edgeShortest': '#ECC94B',
        'edgeMST': '#ECC94B',
    };

    return (
        {
            nodes: [
                {
                    id: `node-0`,
                    label: 'A',
                    style: {
                        label: {
                            value: 'A'
                        }
                    },
                },
                {
                    id: `node-1`,
                    label: 'B',
                    style: {
                        label: {
                            value: 'B'
                        }
                    },
                },
                {
                    id: `node-2`,
                    label: 'C',
                    style: {
                        label: {
                            value: 'C'
                        }
                    },
                },
                {
                    id: `node-3`,
                    label: 'D',
                    style: {
                        label: {
                            value: 'D'
                        }
                    },
                },
                {
                    id: `node-4`,
                    label: 'E',
                    style: {
                        label: {
                            value: 'E'
                        }
                    },
                },
                {
                    id: `node-5`,
                    label: 'F',
                    style: {
                        label: {
                            value: 'F'
                        }
                    },
                },
            ],
            edges: [
                {
                    id: `edge-0`,
                    source: 'node-0',
                    target: 'node-1',
                    style: {
                        label: {
                            value: '12',
                            offset: [0, 0],
                        },
                        keyshape: {
                            stroke: colors['edgeNormal'],
                            lineWidth: 1,
                        },
                    },
                    weight: 12,
                },
                {
                    id: `edge-1`,
                    source: 'node-0',
                    target: 'node-2',
                    style: {
                        label: {
                            value: '5',
                            offset: [0, 0],
                        },
                        keyshape: {
                            stroke: colors['edgeNormal'],
                            lineWidth: 1,
                        },
                    },
                    weight: 5,
                },
                {
                    id: `edge-2`,
                    source: 'node-0',
                    target: 'node-3',
                    style: {
                        label: {
                            value: '9',
                            offset: [0, 0],
                        },
                        keyshape: {
                            stroke: colors['edgeNormal'],
                            lineWidth: 1,
                        },
                    },
                    weight: 9,
                },
                {
                    id: `edge-3`,
                    source: 'node-0',
                    target: 'node-4',
                    style: {
                        label: {
                            value: '15',
                            offset: [0, 0],
                        },
                        keyshape: {
                            stroke: colors['edgeNormal'],
                            lineWidth: 1,
                        },
                    },
                    weight: 15,
                },
                {
                    id: `edge-4`,
                    source: 'node-5',
                    target: 'node-0',
                    style: {
                        label: {
                            value: '2',
                            offset: [0, 0],
                        },
                        keyshape: {
                            stroke: colors['edgeNormal'],
                            lineWidth: 1,
                        },
                    },
                    weight: 2,
                },
                {
                    id: `edge-5`,
                    source: 'node-1',
                    target: 'node-2',
                    style: {
                        label: {
                            value: '14',
                            offset: [0, 0],
                        },
                        keyshape: {
                            stroke: colors['edgeNormal'],
                            lineWidth: 1,
                        },
                    },
                    weight: 14,
                },
                {
                    id: `edge-6`,
                    source: 'node-3',
                    target: 'node-1',
                    style: {
                        label: {
                            value: '2',
                            offset: [0, 0],
                        },
                        keyshape: {
                            stroke: colors['edgeNormal'],
                            lineWidth: 1,
                        },
                    },
                    weight: 2,
                },
                {
                    id: `edge-7`,
                    source: 'node-1',
                    target: 'node-4',
                    style: {
                        label: {
                            value: '13',
                            offset: [0, 0],
                        },
                        keyshape: {
                            stroke: colors['edgeNormal'],
                            lineWidth: 1,
                        },
                    },
                    weight: 13,
                },
                {
                    id: `edge-8`,
                    source: 'node-5',
                    target: 'node-1',
                    style: {
                        label: {
                            value: '6',
                            offset: [0, 0],
                        },
                        keyshape: {
                            stroke: colors['edgeNormal'],
                            lineWidth: 1,
                        },
                    },
                    weight: 6,
                },
                {
                    id: `edge-9`,
                    source: 'node-2',
                    target: 'node-3',
                    style: {
                        label: {
                            value: '16',
                            offset: [0, 0],
                        },
                        keyshape: {
                            stroke: colors['edgeNormal'],
                            lineWidth: 1,
                        },
                    },
                    weight: 16,
                },
                {
                    id: `edge-10`,
                    source: 'node-2',
                    target: 'node-4',
                    style: {
                        label: {
                            value: '6',
                            offset: [0, 0],
                        },
                        keyshape: {
                            stroke: colors['edgeNormal'],
                            lineWidth: 1,
                        },
                    },
                    weight: 6,
                },
                {
                    id: `edge-11`,
                    source: 'node-5',
                    target: 'node-2',
                    style: {
                        label: {
                            value: '1',
                            offset: [0, 0],
                        },
                        keyshape: {
                            stroke: colors['edgeNormal'],
                            lineWidth: 1,
                        },
                    },
                    weight: 1,
                },
                {
                    id: `edge-12`,
                    source: 'node-3',
                    target: 'node-4',
                    style: {
                        label: {
                            value: '15',
                            offset: [0, 0],
                        },
                        keyshape: {
                            stroke: colors['edgeNormal'],
                            lineWidth: 1,
                        },
                    },
                    weight: 15,
                },
                {
                    id: `edge-13`,
                    source: 'node-5',
                    target: 'node-3',
                    style: {
                        label: {
                            value: '18',
                            offset: [0, 0],
                        },
                        keyshape: {
                            stroke: colors['edgeNormal'],
                            lineWidth: 1,
                        },
                    },
                    weight: 18,
                },
                {
                    id: `edge-14`,
                    source: 'node-4',
                    target: 'node-5',
                    style: {
                        label: {
                            value: '4',
                            offset: [0, 0],
                        },
                        keyshape: {
                            stroke: colors['edgeNormal'],
                            lineWidth: 1,
                        },
                    },
                    weight: 4,
                },
            ],
        }
    );
}