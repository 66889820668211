import React, {useEffect, useState} from "react";
import {AbsoluteCenter, Box, Progress, Stack} from "@chakra-ui/react";
import Logo from "../../components/widgets/common/Logo";

const MobileLoading = () => {
    return (
        <AbsoluteCenter>
            <Stack direction={'column'}>
                <Logo height='50' />
                <Progress size='xs' colorScheme={'teal'} isIndeterminate />
            </Stack>
        </AbsoluteCenter>
    );
};

const DesktopLoading = () => {
    return (
        <AbsoluteCenter>
            <Stack direction={'column'}>
                <Logo height='200' />
                <Progress size='xs' colorScheme={'teal'} isIndeterminate />
            </Stack>
        </AbsoluteCenter>
    );
}

const LoadingPage = () => {
    const [mobile, setMobile] = useState(window.innerWidth <= 500);

    const handleWindowSizeChange = () => {
        setMobile(window.innerWidth <= 500);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

  return (
      <Box height={'calc(100vh)'}>
          {
              mobile
                  ?
                  <MobileLoading />
                  :
                  <DesktopLoading />
          }
      </Box>
  );
};

export default LoadingPage;