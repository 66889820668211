/**
 * Dijkstra's Algorithm
 * @author Yiting Liu, Yidan Huang, Sicheng Chen
 */

export const dijkstra = (data, from, to, speed, handleHighlightEdgesInQueue, setDisableAll, setLog, log, history, setHistory) => {
    //begin vis
    setDisableAll(true);
    let timeStep = 0;

    let edgeRenderQueue = [];
    /* edgeRenderQueue: [timeStep: [step: {id, type}, ...], ...] */
    let steps=0;
    let logList = log;

    logList.push({
        level: 'debug',
        text: 'Start running Dijkstra...'
    });

    const addStep = (id, type) => {
        if (edgeRenderQueue.length === timeStep) {
            /* add timeStep */
            const time = [{
                id: id,
                type: type
            }];
            edgeRenderQueue.push(time);
        } else {
            /* add step to timeStep */
            const time = edgeRenderQueue[edgeRenderQueue.length - 1];
            edgeRenderQueue[edgeRenderQueue.length - 1] = time.concat({
                id: id,
                type: type,
            });
        }
    };
    //end vis

    const n = data.nodes.length;
    let dist = new Array(n).fill().map(() => new Array(n).fill(Infinity));
    let prev = new Array(n).fill().map(() => new Array(n).fill(-1));


    const getNodeNumById = (id) => {
        for (let i = 0; i < data.nodes.length; i++) {
            if (data.nodes[i].id === id) {
                return i;
            }
        }

        return -1;
    }

    const getNodeIdByNum = (num) => {
        return data.nodes.find(node => getNodeNumById(node.id) === num).id;
    }

    data.edges.forEach((edge) => {
        //begin vis
        addStep(edge.id, 'edgeVisited');
        timeStep++;
        //end vis

        dist[getNodeNumById(edge.source)][getNodeNumById(edge.target)] = edge.weight;
        prev[getNodeNumById(edge.source)][getNodeNumById(edge.target)] = getNodeNumById(edge.source);

        //begin vis

        logList.push({
            level: 'info',
            text: 'Edge '
                + data.nodes.find(node => node.id === data.edges.find(e => e.id === edge.id).source).label
                + ' -> '
                + data.nodes.find(node => node.id === data.edges.find(e => e.id === edge.id).target).label
                + ' (w='
                + data.edges.find(e => e.id === edge.id).weight
                +') visited.'
        });
        /* handle array update */

        //end vis
    });
    for (let i = 0; i < n; i++) {
        dist[i][i] = 0;
        prev[i][i] = i;
        
        //begin vis
        /* handle array update */
        //end vis
    }
    const a=getNodeNumById(from);
    const b=getNodeNumById(to);
    const INF = Infinity;
        const visited = new Array(n).fill(false); // 记录每个节点是否已经被访问
        for (let i = 0; i < n; i++) {
            let minDist = INF;
            let u = 0;
            for (let j = 0; j < n; j++) {
                if (!visited[j] && dist[a][j] < minDist) {
                    minDist = dist[a][j];
                    u = j;
                }
                steps++;
            }
            visited[u] = true;
            for (let v = 0; v < n ; v++) {
                if (dist[u][v] !== INF) {
                    const newDist = dist[a][u] + dist[u][v];
                    if (newDist < dist[a][v]) {
                        dist[a][v] = newDist;
                        prev[a][v] = u;
                    }
                    
                }
                steps++;
            }
        }
    const pathRecons = (u, v)=> {
        let path = [];

        if (prev[u][v] === -1) {
            return [];
        }

        path.push(v);

        while (u !== v) {
            v = prev[u][v];
            path.unshift(v);
        }

        return path;
    }

    const pathNodeNums = pathRecons(getNodeNumById(from), getNodeNumById(to));
    const pathNodeLabels = pathNodeNums.map(num => data.nodes[num].label);


    //begin vis
    for (let i = 0; i < pathNodeNums.length - 1; i++) {
        const edgeId = getEdgeId(data, getNodeIdByNum(pathNodeNums[i]), getNodeIdByNum(pathNodeNums[i + 1]));
        addStep(edgeId, 'edgeShortest');
    }

    let shortestPathText = 'Shortest path: ';

    for (let i = 0; i < pathNodeLabels.length; i++) {
        shortestPathText = shortestPathText + pathNodeLabels[i];
        if (i !== pathNodeLabels.length - 1) {
            shortestPathText = shortestPathText + ' -> ';
        } else {
            shortestPathText = shortestPathText + '.';
        }
    }

    logList.push({
        level: 'info',
        text: shortestPathText
    });

    logList.push({
        level: 'warning',
        text: 'Total time steps: ' + edgeRenderQueue.length
    });

    setHistory({
        ...history,
        dijkstra: (steps)
    });

    logList.push({
        level: 'debug',
        text: 'Johnson completed. Start rendering...'
    });


    for (let i = 0; i < edgeRenderQueue.length; i++) {
        setTimeout(() => {
            handleHighlightEdgesInQueue(edgeRenderQueue[i]);
        }, speed * i);
    }

    timeStep++;

    setTimeout(() => {
        setDisableAll(false);
    }, speed * timeStep);

    setLog(logList);
    //end vis
};

const getEdgeId = (data, from, to) => {
    const edge = data.edges.find(edge => (edge.source === from && edge.target === to));
    if (edge === undefined) {
        return '';
    } else {
        return edge.id;
    }
};
