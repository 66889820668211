import React, {Suspense, useEffect, useState} from "react";
import { Routes, Route } from "react-router-dom";

import MazePathFindingPage from "./pages/maze/path-finding"
import StartPage from "./pages";
import GraphShortestPathPage from "./pages/graph/shortest-path";
import GraphMSTPage from "./pages/graph/mst";
import SideBar from "./components/widgets/common/SideBar";
import {Box} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import SettingsPage from "./pages/settings";
import LoadingPage from "./pages/loading";
import {MathJaxContext} from "better-react-mathjax";
import LegalPage from "./pages/settings/legal";

import "./App.css";

const App = () => {
    const { i18n } = useTranslation();
    const [ isLoading, setIsLoading ] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);

    let langClass;

    switch (i18n.resolvedLanguage) {
        case 'zh-CHS':
            langClass = 'chinese-mainland';
            break;

        case 'zh-CHT':
            langClass = 'chinese-taiwan';
            break;

        case 'ja':
            langClass = 'japanese';
            break;

        default:
            langClass = 'western';
            break;
    }

    return (
        <div className={langClass}>
            {
                isLoading ? <LoadingPage /> :
                    <Suspense fallback={<SideBar />}>
                        <MathJaxContext>
                            <SideBar>
                                <Box p={{base: 1, md: 4}}>
                                    <Routes>
                                        <Route path={"/"} Component={StartPage} />
                                        <Route path={"/maze/path-finding"} Component={MazePathFindingPage} />
                                        <Route path={"/graph/shortest-path"} Component={GraphShortestPathPage} />
                                        <Route path={"/graph/mst"} Component={GraphMSTPage} />
                                        <Route path={"/settings"} Component={SettingsPage} />
                                        <Route path={"/settings/legal"} Component={LegalPage} />
                                    </Routes>
                                </Box>
                            </SideBar>
                        </MathJaxContext>
                    </Suspense>
            }
        </div>
    );
}

export default App;
