import React from "react";

import "./index.scss";


/**
 * Node component for visualization of path-finding algorithms.
 *
 * The id of the node is 'node-<row>-<col>'.
 *
 * @param row The row number of the Node
 * @param column The column number of the Node
 * @param isEnd Variable for determining the style node-end
 * @param isBegin Variable for determining the style node-begin
 * @param isWall Variable for determining the style node-wall
 * @param onNodeClick Function handles onClick events
 * @param onNodeOver Function handles onMouseOver events
 * @param onNodeOut Function handles onMouseOut events
 * @returns {JSX.Element} The node element
 */
const Node = ({
    row,
    column,
    isEnd,
    isBegin,
    isWall,
    onNodeClick,
    onNodeOver,
    onNodeOut,
}) => {

    const styleClassName = isEnd ? 'node-end' : isBegin ? 'node-begin' : isWall ? 'node-wall' : '';

    return (
        <div
            id={`node-${row}-${column}`}
            className={`node ${styleClassName}`}
            onClick={() => onNodeClick(row, column)}
            onMouseOver={() => onNodeOver(row, column)}
            onMouseOut={() => onNodeOut(row, column)}
        />
    );
};


export default Node;