import React, {useEffect, useState} from "react";
import {Box, Button, FormControl, HStack, Select, Stack, StackDivider} from "@chakra-ui/react";
import {floydWarshall} from "../../../algorithms/graph/shortest-path/floyd-warshall";
import {aStar} from "../../../algorithms/graph/shortest-path/a-star";
import {useTranslation} from "react-i18next";
import {johnson} from "../../../algorithms/graph/shortest-path/johnson";
import {dijkstra} from "../../../algorithms/graph/shortest-path/dijkstra";
import {bellmanFord} from "../../../algorithms/graph/shortest-path/bellman-ford";

const ShortestPathControlPanel = ( props ) => {
    const [ visSource, setVisSource ] = useState('');
    const [ visTarget, setVisTarget ] = useState('');
    const [ speed, setSpeed ] = useState(150);

    const { t } = useTranslation(['widgets', 'common']);

    useEffect(() => {
        if (props.isEditingGraph) {
            setVisTarget('');
            setVisSource('');
        }
    }, [props.isEditingGraph]);

    const handleRunVisualization = () => {
        switch (props.algorithm) {
            case 'floyd-warshall':
                floydWarshall(props.data, visSource, visTarget, speed, props.handleHighlightEdgesInQueue, props.setDisableAll, props.setLog, props.log, props.history, props.setHistory);
                break;
            case 'a-star':
                aStar(props.data, visSource, visTarget, speed, props.handleHighlightEdgesInQueue, props.setDisableAll, props.setLog, props.log, props.history, props.setHistory);
                break;

            case 'johnson':
                johnson(props.data, visSource, visTarget, speed, props.handleHighlightEdgesInQueue, props.setDisableAll, props.setLog, props.log, props.history, props.setHistory);
                break;

            case 'dijkstra':
                dijkstra(props.data, visSource, visTarget, speed, props.handleHighlightEdgesInQueue, props.setDisableAll, props.setLog, props.log, props.history, props.setHistory);
                break;

            case 'bellman-ford':
                bellmanFord(props.data, visSource, visTarget, speed, props.handleHighlightEdgesInQueue, props.setDisableAll, props.setLog, props.log, props.history, props.setHistory);
                break;

            default:
                alert(t('graph.shortest-path-control-panel.alerts.select-algo', {ns: 'widgets'}));
                return;
        }
    };

    return (
        <Stack divider={<StackDivider />} spacing='4'>
            <Box>
                <FormControl >
                    <Stack direction={'column'}>
                        <HStack>
                            <Select fontSize={'sm'} isDisabled={props.disableAll} value={visSource} onChange={(event) => {setVisSource(event.target.value)}}>
                                <option value={''} disabled={true}>{t('graph.shortest-path-control-panel.select.from', {ns: 'widgets'})}</option>
                                {props.data.nodes.map((node, index) => (
                                    <option value={node.id} key={index}>{node.label}</option>
                                ))}
                            </Select>
                            <Select fontSize={'sm'} isDisabled={props.disableAll} value={visTarget} onChange={(event) => {setVisTarget(event.target.value)}}>
                                <option value={''} disabled={true}>{t('graph.shortest-path-control-panel.select.to', {ns: 'widgets'})}</option>
                                {props.data.nodes.map((node, index) => (
                                    <option value={node.id} key={index}>{node.label}</option>
                                ))}
                            </Select>
                            <Select fontSize={'sm'} isDisabled={props.disableAll} value={speed} onChange={(event) => {setSpeed(event.target.value)}}>
                                <option value={50}>{t('speeds.very-fast', {ns: 'common'})}</option>
                                <option value={100}>{t('speeds.fast', {ns: 'common'})}</option>
                                <option value={150}>{t('speeds.normal', {ns: 'common'})}</option>
                                <option value={200}>{t('speeds.slow', {ns: 'common'})}</option>
                                <option value={250}>{t('speeds.very-slow', {ns: 'common'})}</option>
                            </Select>
                        </HStack>
                        <HStack>
                            <Select fontSize={'sm'} isDisabled={props.disableAll} value={props.algorithm} onChange={(event) => props.setAlgorithm(event.target.value)}>
                                <option disabled={true} value={''}>{t('modules.graph.shortest-path.algorithms.algorithm', {ns: 'common'})}</option>
                                <option value="a-star">{t('modules.graph.shortest-path.algorithms.a-star', {ns: 'common'})}</option>
                                <option value="bellman-ford">{t('modules.graph.shortest-path.algorithms.bellman-ford', {ns: 'common'})}</option>
                                <option value="dijkstra">{t('modules.graph.shortest-path.algorithms.dijkstra', {ns: 'common'})}</option>
                                <option value="floyd-warshall">{t('modules.graph.shortest-path.algorithms.floyd-warshall', {ns: 'common'})}</option>
                                <option value="johnson">{t('modules.graph.shortest-path.algorithms.johnson', {ns: 'common'})}</option>
                            </Select>
                            <Button
                                colorScheme='teal'
                                size='md'
                                isLoading={props.disableAll}
                                fontSize={'sm'}
                                onClick={() => handleRunVisualization()}
                            >
                                {t('graph.shortest-path-control-panel.button.run', {ns: 'widgets'})}
                            </Button>
                            <Button
                                colorScheme='teal'
                                size='md'
                                isDisabled={props.disableAll}
                                fontSize={'sm'}
                                onClick={() => props.handleClearPath()}
                            >
                                {t('graph.shortest-path-control-panel.button.clear-path', {ns: 'widgets'})}
                            </Button>
                        </HStack>
                    </Stack>
                </FormControl>
            </Box>
        </Stack>
    );
};

export default ShortestPathControlPanel;