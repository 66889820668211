/**
 * A* Algorithm
 * @author Youran Li
 */

export const aStar = (data, from, to, speed, handleHighlightEdgesInQueue, setDisableAll, setLog, log, history, setHistory) => {
    //begin vis
    setDisableAll(true);
    let timeStep = 0;
    let step=0;
    let edgeRenderQueue = [];
    /* edgeRenderQueue: [timeStep: [step: {id, type}, ...], ...] */

    let logList = log;

    logList.push({
        level: 'debug',
        text: 'Start running A*...'
    });

    const addStep = (id, type) => {
        if (edgeRenderQueue.length === timeStep) {
            /* add timeStep */
            const time = [{
                id: id,
                type: type,
            }];
            edgeRenderQueue.push(time);
        } else {
            /* add step to timeStep */
            const time = edgeRenderQueue[edgeRenderQueue.length - 1];
            edgeRenderQueue[edgeRenderQueue.length - 1] = time.concat({
                id: id,
                type: type,
            });
        }
    };

    //end vis
    /* Path Recons */

    const pathRecons = (u, v) => {
        let path = [];

        if (prev[u][v] === -1) {
            return [];
        }

        path.push(v);

        while (u !== v) {
            v = prev[u][v];
            path.unshift(v);
        }

        return path;
    }

    const n = data.nodes.length;
    let dist = new Array(n).fill().map(() => new Array(n).fill(Infinity));
    let prev = new Array(n).fill().map(() => new Array(n).fill(-1));

    const getNodeNumById = (id) => {
        for (let i = 0; i < data.nodes.length; i++) {
            if (data.nodes[i].id === id) {
                return i;
            }
        }

        return -1;
    }

    const getNodeIdByNum = (num) => {
        return data.nodes.find(node => getNodeNumById(node.id) === num).id;
    }

    data.edges.forEach((edge) => {
        //begin vis
        addStep(edge.id, 'edgeVisited');
        timeStep++;
        //end vis

        dist[getNodeNumById(edge.source)][getNodeNumById(edge.target)] = edge.weight;
        prev[getNodeNumById(edge.source)][getNodeNumById(edge.target)] = getNodeNumById(edge.source);

        //begin vis
        logList.push({
            level: 'info',
            text: 'Edge '
                + data.nodes.find(node => node.id === data.edges.find(e => e.id === edge.id).source).label
                + ' -> '
                + data.nodes.find(node => node.id === data.edges.find(e => e.id === edge.id).target).label
                + ' (w='
                + data.edges.find(e => e.id === edge.id).weight
                +') visited.'
        });

        /* handle array update */

        //end vis
    });

    for (let i = 0; i < n; i++) {
        dist[i][i] = 0;
        prev[i][i] = i;
        //begin vis
        /* handle array update */
        //end vis
    }

    //Generate prediction function
    // (since there is no prediction function in the original data,
    // the possible prediction value is calculated by means of average value
    // and connectivity of points)
    var h = [];
    for (let k = 0; k < n; k++) {
        h.push(Infinity);
    }
    for (let k = 0; k < n; k++) {
        for (let i = 0; i < n; i++) {
            for (let j = 0; j < n; j++) {
                if (h[i] > h[j] + dist[i][j]) {
                    h[i] = h[j] + dist[i][j];
                }
                step++;
            }
        }
    }

    //a-star algorithms
    //Since the prediction function is generated later, the accuracy of the prediction function
    // cannot be guaranteed.
    var openList = [],
        closeList = [],
        startP = getNodeNumById(from);
    openList.push(startP);
    while (!!openList.length){
        openList.sort((nodeA, nodeB) => (dist[startP][nodeA] + h[nodeA]) - (dist[startP][nodeB] + h[nodeB]) );
        const closestNode = openList.shift();
        for( let j = 0; j < n; j++){
            if(!(dist[closestNode][j] === Infinity ) && !(closestNode === j)) {
                if (!existList(j, openList) && !existList(j, closeList)) {
                    openList.push(j);
                } else if (existList(j, closeList)) {
                    continue;
                }
                if (dist[startP][closestNode] + dist[closestNode][j] < dist[startP][j] && !existList(j, closeList)) {
                    dist[startP][j] = dist[startP][closestNode] + dist[closestNode][j];
                    prev[startP][j] = prev[closestNode][j];
                }
            }
            step++;
        }
        closeList.push(closestNode);
    }


    const pathNodeNums = pathRecons(getNodeNumById(from), getNodeNumById(to));
    const pathNodeLabels = pathNodeNums.map(num => data.nodes[num].label);

    //begin vis
    for (let i = 0; i < pathNodeNums.length - 1; i++) {
        const edgeId = getEdgeId(data, getNodeIdByNum(pathNodeNums[i]), getNodeIdByNum(pathNodeNums[i + 1]));
        addStep(edgeId, 'edgeShortest');
    }

    for (let i = 0; i < edgeRenderQueue.length; i++) {
        setTimeout(() => {
            handleHighlightEdgesInQueue(edgeRenderQueue[i]);
        }, speed * i);
    }

    let shortestPathText = 'Shortest path: ';

    for (let i = 0; i < pathNodeLabels.length; i++) {
        shortestPathText = shortestPathText + pathNodeLabels[i];
        if (i !== pathNodeLabels.length - 1) {
            shortestPathText = shortestPathText + ' -> ';
        } else {
            shortestPathText = shortestPathText + '.';
        }
    }

    logList.push({
        level: 'info',
        text: shortestPathText
    });

    logList.push({
        level: 'warning',
        text: 'Total time steps: ' + edgeRenderQueue.length
    });

    
    setHistory({
        ...history,
        aStar: step
    });

    logList.push({
        level: 'debug',
        text: 'A* completed. Start rendering...'
    });

    timeStep++;

    setTimeout(() => {
        setDisableAll(false);
    }, speed * timeStep);


    setLog(logList);
    //end vis
};

const getEdgeId = (data, from, to) => {
    const edge = data.edges.find(edge => (edge.source === from && edge.target === to));
    if (edge === undefined) {
        return '';
    } else {
        return edge.id;
    }
};

function existList(point,list) {
    for(let i in list) {
        if(point === list[i]) {
            return i;
        }
    }
    return false;
}
