import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import {ChakraProvider, ColorModeScript} from '@chakra-ui/react'

import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';

import './i18n';
import theme from './theme';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <ChakraProvider>
        <Router>
            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
            <App />
        </Router>
    </ChakraProvider>
);

// If you want to start measuring performance in your redux, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
