import React, {useEffect, useState} from "react";
import {
    Box,
    Card,
    CardBody,
    CardHeader,
    Container,
    FormControl, FormLabel,
    Heading, HStack, Link, Radio, RadioGroup,
    Stack,
    StackDivider,
    Text, useColorMode
} from "@chakra-ui/react";
import LanguageSelectBox from "../../components/widgets/common/LanguageSelectBox";
import {useTranslation} from "react-i18next";

const SettingsPage = () => {
    const { t } = useTranslation(['common', 'pages']);

    const { setColorMode } = useColorMode();

    const [ color, setColor ] = useState('system');

    useEffect(() => {
        setColorMode(color);
    }, [color]);


    return (
        <>
            <Stack direction={'column'}>
                <Heading p={4}>{t('pages:settings.title')}</Heading>
                <Card>
                    <CardHeader>
                        <Heading size='md'>{t('pages:settings.display.title')}</Heading>
                    </CardHeader>

                    <CardBody>
                        <Stack divider={<StackDivider />} spacing='4'>
                            <Box>
                                <FormControl>
                                    <FormLabel>
                                        <Heading size='sm'>
                                            {t('pages:settings.display.language')}
                                        </Heading>
                                    </FormLabel>
                                    <LanguageSelectBox />
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl>
                                    <FormLabel>
                                        <Heading size='sm'>
                                            {t('pages:settings.display.appearence.title')}
                                        </Heading>
                                    </FormLabel>
                                    <RadioGroup size={'sm'} value={color}>
                                        <HStack spacing='24px'>
                                            <Radio value='system' onChange={(e) => {setColor(e.target.value)}} colorScheme={"teal"}>{t('pages:settings.display.appearence.follow-system')}</Radio>
                                            <Radio value='light' onChange={(e) => {setColor(e.target.value)}} colorScheme={"teal"}>{t('pages:settings.display.appearence.light')}</Radio>
                                            <Radio value='dark' onChange={(e) => {setColor(e.target.value)}} colorScheme={"teal"}>{t('pages:settings.display.appearence.dark')}</Radio>
                                        </HStack>
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Stack>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader>
                        <Heading size='md'>{t('pages:settings.about.title')}</Heading>
                    </CardHeader>

                    <CardBody>
                        <Stack divider={<StackDivider />} spacing='4'>
                            <Box>
                                <Heading size='sm'>
                                    {t('pages:settings.about.version')}
                                </Heading>
                                <Text pt='2' fontSize='sm'>
                                    {t('site.version')}
                                </Text>
                            </Box>
                            <Box>
                                <Heading size='sm'>
                                    {t('pages:settings.about.release-date')}
                                </Heading>
                                <Text pt='2' fontSize='sm'>
                                    {t('site.date')}
                                </Text>
                            </Box>
                        </Stack>
                    </CardBody>
                </Card>
            </Stack>
            <Container centerContent p={4}>
                <Text fontSize='xs'>
                    {t('site.copyright')}
                </Text>
                <Text fontSize='xs' as='u'>
                    <Link href={'/settings/legal'}>License Agreement</Link>
                </Text>
            </Container>
        </>
    );
};

export default SettingsPage;