import React from "react";

const Logo = ({ height = 32 }) => {
    return (
        <svg viewBox="0 0 128 48" height={height}>
            <g className="undefined-text-0" data-fill-palette-color="primary" id="text-0"
               transform="matrix(0.613791, 0, 0, 0.613791, -0.368275, 33.943027)">
                <path
                    d="M0.6 0L15.45-35.5 18.4-35.5 33.15 0 29.45 0 24.85-11.1 8.9-11.1 4.35 0 0.6 0ZM9.65-13.85L24.05-13.85 16.9-31.4 9.65-13.85ZM62.25 0L38.25 0 38.25-35.5 41.75-35.5 41.75-3.1 62.25-3.1 62.25 0ZM81.65 0.25L81.65 0.25Q78.1 0.25 75.05-1.28 72-2.8 69.75-5.4 67.5-8 66.27-11.2 65.05-14.4 65.05-17.8L65.05-17.8Q65.05-21.4 66.27-24.63 67.5-27.85 69.7-30.35 71.9-32.85 74.9-34.3 77.9-35.75 81.45-35.75L81.45-35.75Q86.7-35.75 90-33.6 93.3-31.45 95-28L95-28 92.3-26.2Q90.6-29.5 87.75-31.05 84.9-32.6 81.4-32.6L81.4-32.6Q78.45-32.6 76.07-31.35 73.7-30.1 72-28 70.3-25.9 69.42-23.25 68.55-20.6 68.55-17.75L68.55-17.75Q68.55-14.75 69.55-12.08 70.55-9.4 72.4-7.33 74.25-5.25 76.67-4.08 79.1-2.9 81.9-2.9L81.9-2.9Q85.15-2.9 88.05-4.53 90.95-6.15 93.6-9.55L93.6-9.55 93.6-5.85Q88.55 0.25 81.65 0.25ZM93.05 0L93.05-14.75 85.15-14.75 85.15-17.45 96-17.45 96 0 93.05 0ZM117.49 0.25L117.49 0.25Q113.74 0.25 110.69-1.25 107.64-2.75 105.44-5.33 103.24-7.9 102.04-11.13 100.84-14.35 100.84-17.75L100.84-17.75Q100.84-21.3 102.09-24.53 103.34-27.75 105.62-30.28 107.89-32.8 110.94-34.27 113.99-35.75 117.54-35.75L117.54-35.75Q121.29-35.75 124.34-34.2 127.39-32.65 129.57-30.05 131.74-27.45 132.94-24.25 134.14-21.05 134.14-17.7L134.14-17.7Q134.14-14.15 132.89-10.93 131.64-7.7 129.39-5.18 127.14-2.65 124.09-1.2 121.04 0.25 117.49 0.25ZM104.34-17.75L104.34-17.75Q104.34-14.85 105.29-12.18 106.24-9.5 107.99-7.43 109.74-5.35 112.17-4.13 114.59-2.9 117.49-2.9L117.49-2.9Q120.54-2.9 122.97-4.17 125.39-5.45 127.12-7.58 128.84-9.7 129.74-12.35 130.64-15 130.64-17.75L130.64-17.75Q130.64-20.65 129.69-23.3 128.74-25.95 126.97-28.05 125.19-30.15 122.79-31.35 120.39-32.55 117.49-32.55L117.49-32.55Q114.49-32.55 112.07-31.3 109.64-30.05 107.92-27.93 106.19-25.8 105.27-23.15 104.34-20.5 104.34-17.75Z"
                    fill="#319795" data-fill-palette-color="primary"/>
                <path
                    d="M134.79-35.5L138.44-35.5 151.14-4.35 163.79-35.5 167.44-35.5 152.74 0 149.54 0 134.79-35.5ZM176.04 0L172.54 0 172.54-35.5 176.04-35.5 176.04 0ZM207.39-31.65L205.69-28.85Q204.94-29.7 203.94-30.38 202.94-31.05 201.69-31.55 200.44-32.05 199.01-32.35 197.59-32.65 195.99-32.65L195.99-32.65Q191.34-32.65 189.16-30.88 186.99-29.1 186.99-26.05L186.99-26.05Q186.99-23.95 188.01-22.75 189.04-21.55 191.24-20.8 193.44-20.05 196.84-19.25L196.84-19.25Q200.64-18.45 203.39-17.35 206.14-16.25 207.64-14.38 209.14-12.5 209.14-9.3L209.14-9.3Q209.14-6.85 208.19-5.05 207.23-3.25 205.54-2.05 203.84-0.85 201.48-0.28 199.14 0.3 196.34 0.3L196.34 0.3Q193.59 0.3 191.06-0.28 188.54-0.85 186.29-1.98 184.04-3.1 182.09-4.85L182.09-4.85 183.84-7.7Q184.79-6.7 186.11-5.83 187.44-4.95 189.06-4.25 190.69-3.55 192.56-3.18 194.44-2.8 196.44-2.8L196.44-2.8Q200.69-2.8 203.06-4.35 205.44-5.9 205.44-8.95L205.44-8.95Q205.44-11.15 204.23-12.48 203.04-13.8 200.64-14.65 198.23-15.5 194.74-16.3L194.74-16.3Q191.04-17.15 188.49-18.2 185.94-19.25 184.66-20.98 183.39-22.7 183.39-25.55L183.39-25.55Q183.39-28.85 185.01-31.13 186.64-33.4 189.49-34.58 192.34-35.75 196.04-35.75L196.04-35.75Q198.39-35.75 200.41-35.27 202.44-34.8 204.16-33.9 205.89-33 207.39-31.65L207.39-31.65Z"
                    fill="#89939e" data-fill-palette-color="secondary"/>
            </g>
        </svg>
    );
};

export default Logo;