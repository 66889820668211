import React from "react"

import {Box, Heading, Text, Card, Stack, CardHeader, CardBody, StackDivider} from "@chakra-ui/react"


import { useTranslation } from "react-i18next"

const StartPage = () => {
    const { t } = useTranslation(['common', 'pages']);

    return (
        <>
            <Stack direction={'column'}>
                <Heading p={4}>{t('pages:index.start')}</Heading>

                <Card>
                    <CardHeader>
                        <Heading size='md'>{t('pages:index.welcome')}</Heading>
                    </CardHeader>

                    <CardBody>
                        <Stack divider={<StackDivider />} spacing='4'>
                            <Box>
                                <Heading size='sm'>
                                    {t('pages:index.welcome_to_algo_vis')}
                                </Heading>
                                <Text pt='2' fontSize='sm'>
                                    {t('pages:index.welcome_to_algo_vis_an_application_that_helps_you')}
                                </Text>
                            </Box>
                            <Box>
                                <Heading size='sm'>
                                    {t('pages:index.how_to_use')}
                                </Heading>
                                <Text pt='2' fontSize='sm'>
                                    {t('pages:index.you_can_use_the_sidebar_to_navigate_between_the_mo')}
                                </Text>
                            </Box>
                        </Stack>
                    </CardBody>
                </Card>

                <Card>
                    <CardHeader>
                        <Heading size='md'>{t('pages:index.modules')}</Heading>
                    </CardHeader>

                    <CardBody>
                        <Stack divider={<StackDivider />} spacing='4'>
                            <Box>
                                <Heading size='sm'>
                                    {t('common:modules.graph.shortest-path.title')}
                                </Heading>
                                <Text pt='2' fontSize='sm'>
                                    {t('common:modules.graph.shortest-path.description')}
                                </Text>
                            </Box>
                            <Box>
                                <Heading size='sm'>
                                    {t('common:modules.graph.mst.title')}
                                </Heading>
                                <Text pt='2' fontSize='sm'>
                                    {t('common:modules.graph.mst.description')}
                                </Text>
                            </Box>
                            <Box>
                                <Heading size='sm'>
                                    {t('common:modules.misc.maze-path-finding.title')}
                                </Heading>
                                <Text pt='2' fontSize='sm'>
                                    {t('common:modules.misc.maze-path-finding.description')}
                                </Text>
                            </Box>
                        </Stack>
                    </CardBody>
                </Card>

            </Stack>
        </>
    )
}

export default StartPage
