import React from "react"
import {
    IconButton,
    Box,
    CloseButton,
    Flex,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    useDisclosure,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import {
    FiCompass,
    FiMenu,
    FiSettings
} from "react-icons/fi"
import Logo from "../Logo"
import { useTranslation } from "react-i18next"
import {TbBinaryTree, TbDirectionSign, TbWall} from "react-icons/tb";

import "./index.css";

export default function SideBar({ children }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
            <SidebarContent
                onClose={() => onClose}
                display={{ base: "none", md: "block" }}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full"
            >
                <DrawerContent>
                    <SidebarContent onClose={onClose} />
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav onOpen={onOpen} />
            <Box ml={{ base: 0, md: 60 }} p="4">
                {children}
            </Box>
        </Box>
    )
}

const SidebarContent = ({ onClose, ...rest }) => {
    const { t } = useTranslation(['common', 'widgets']);

    const linkItems = [
        {
            name: t("modules.graph.shortest-path.title", {ns: 'common'}),
            href: "/graph/shortest-path",
            icon: TbDirectionSign
        },
        {
            name: t("modules.graph.mst.title", {ns: 'common'}),
            href: "/graph/mst",
            icon: TbBinaryTree
        },
        {
            name: t("modules.misc.maze-path-finding.title", {ns: 'common'}),
            href: "/maze/path-finding",
            icon: TbWall
        },
    ];


    return (
        <Box
            transition="3s ease"
            bg={useColorModeValue("white", "gray.900")}
            borderRight="1px"
            borderRightColor={useColorModeValue("gray.200", "gray.700")}
            w={{ base: "full", md: 60 }}
            pos="fixed"
            h="full"
            overflow='scroll'
            className={'sidebar'}
            {...rest}
        >
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <Logo height={48} />
                <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
            </Flex>
            <NavItem icon={FiCompass} href={"/"}>
                {t("common.sidebar.start", {ns: 'widgets'})}
            </NavItem>
            {linkItems.map(link => (
                <NavItem key={link.name} href={link.href} icon={link.icon}>
                    {link.name}
                </NavItem>
            ))}
            <NavItem icon={FiSettings} href={"/settings"}>
                {t('widgets:common.sidebar.settings')}
            </NavItem>
        </Box>
    )
}

const NavItem = ({ icon, children, href, ...rest }) => {
    return (
        <Link
            as={ReactRouterLink}
            to={href}
            style={{ textDecoration: "none" }}
            _focus={{ boxShadow: "none" }}
        >
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                        bg: 'teal.400',
                        color: 'white',
                }}
                {...rest}
            >
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: 'white',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Link>
    )
}

const MobileNav = ({ onOpen, ...rest }) => {
    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            display={{ base: "flex", md: "none" }}
            height="20"
            alignItems="center"
            bg={useColorModeValue("white", "gray.900")}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue("gray.200", "gray.700")}
            justifyContent={{ base: "space-between", md: "flex-end" }}
            {...rest}
        >
            <IconButton
                display={{ base: "flex", md: "none" }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />
        </Flex>
    )
}
