import React from "react";
import {Box, Stack, Text, useColorModeValue} from "@chakra-ui/react";

const LogRenderer = ( props ) => {
    const data = props.data;

    const bg = useColorModeValue('gray.50', 'gray.800');

    const primary = useColorModeValue('black', 'white');


    return (
        <Stack direction={'column'} background={bg}>
            <Box p={4}>
                {
                    data.map((line, index) => {
                        let color = 'black';
                        switch (line.level) {
                            case 'debug':
                                color = 'gray.400';
                                break;

                            case 'info':
                                color = primary;
                                break;

                            case 'warning':
                                color = 'yellow.400';
                                break;

                            case 'error':
                                color = 'red.400';
                                break;

                            case 'fatal':
                                color = 'red.600';
                                break;

                            default:
                                color = primary;
                                break;
                        }
                        return (
                            <Text color={color} fontSize='sm'
                                  key={index}>{index + ':\t' + line.text}</Text>
                        );
                    })
                }
            </Box>
        </Stack>
    );
};

export default LogRenderer;