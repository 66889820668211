
export function aStar(grid, beginNode, endNode){

    const visitedNodes = [];
    beginNode.distance = 0;

    let openList = [], closeList = [];
    openList.push(beginNode);
    while(!!openList.length){
        //把openList赋值给preOpenList
        openList = openList.sort((nodeA, nodeB) =>  (nodeB.distance + getH(nodeB,endNode) - (nodeA.distance + getH(nodeA,endNode))));

        //查找preOpenList中的最小值，放入roundNodes

        const closestNode = openList.pop();
        //更新OpenList列表
        const Neighbors = getUnvisitedNeighbors(closestNode, grid);
        for (let j = 0; j < Neighbors.length; j++){
            const f = closestNode.distance + getH(Neighbors[j],endNode) + 1;
            if(!Neighbors[j].isWall){
                if(existList(Neighbors[j],openList)){
                    if(f < Neighbors[j].distance + getH(Neighbors[j],endNode)){
                        Neighbors[j].distance = closestNode.distance + 1;
                        Neighbors[j].previousNode = closestNode;
                    }
                }
                else if(existList(Neighbors[j],closeList))
                    continue;
                else {
                    Neighbors[j].distance = closestNode.distance + 1;
                    Neighbors[j].previousNode = closestNode;
                    openList.push(Neighbors[j]);
                }
                Neighbors[j].isVisited = true;
                visitedNodes.push(Neighbors[j]);
            }

        }
        if (closestNode.isWall) {
            continue;
        }
        if (closestNode === endNode) {
            return [visitedNodes, calculatePath(endNode)];
        }

        for(let j = 0; j < Neighbors.length; j++){
            if (Neighbors[j] === endNode) {
                return [visitedNodes, calculatePath(endNode)];
            }
        }
        closestNode.isVisited = true;
        closeList.push(closestNode);
    }
    if(!openList.length){
        return [visitedNodes, ];
    }
}


function existList(point,list) {
    for(var i in list) {
        if(point === list[i]) {
            return i;
        }
    }
    return false;
}

export function getAllNodes(grid = []) {
    const nodes = [];
    for (let i = 0; i < grid.length; i++) {
        for (let j = 0; j < grid[0].length; j++) {
            nodes.push(grid[i][j]);
        }
    }
    return nodes.filter((nodes) => !nodes.isVisited);
}

/*
f(n) = g(n) + h(n)
h(n) as actual value;
h(n) as estimate;
*/

function getUnvisitedNeighbors(node, grid) {
    const neighbors = [];
    const { column, row } = node;
    if (row > 0) neighbors.push(grid[row - 1][column]);
    if (column > 0) neighbors.push(grid[row][column - 1]);
    if (row < grid.length - 1) neighbors.push(grid[row + 1][column]);
    if (column < grid[0].length - 1) neighbors.push(grid[row][column + 1]);
    return neighbors.filter((neighbor) => !neighbor.isVisited);
}

function updateUnvisitedNeighbors(node, grid) {
    const unvisitedNeighbors = getUnvisitedNeighbors(node, grid);
    for (const neighbor of unvisitedNeighbors) {
        if(neighbor.distance > node.distance + 1){
            neighbor.distance = node.distance + 1;
            neighbor.previousNode = node;
        }
    }
}

function calculatePath(finishNode) {
    const shortestPathNodes = [];
    let currentNode = finishNode;
    while (currentNode !== null) {
        shortestPathNodes.unshift(currentNode);
        currentNode = currentNode.previousNode;
    }
    return shortestPathNodes;
}

function getH(node, endNode){
    return Math.abs(getColumn(endNode) - getColumn(node)) + Math.abs(getRow(endNode) - getRow(node));
}

function getColumn(node){
    const { column } = node;
    return column;
}

function getRow(node){
    const { row } = node;
    return row;
}
/* */