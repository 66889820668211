import { UnionFind } from "../../../utils/graph/mst/UnionFind";

export const kruskal = (data, speed, handleHighlightEdgesInQueue, setDisableAll, setLog, log, history, setHistory) => {
    const edges = data.edges;
    const nodes = data.nodes;
    const nodeIds = nodes.map(node => node.id);
    const unionFind = new UnionFind(nodeIds);
    let f = new Set();
    let comparing=0;
    //begin vis
    let timeStep = 0;
    setDisableAll(true);

    let edgeRenderQueue = [];
    /* edgeRenderQueue: [timeStep: [step: {id, type}, ...], ...] */

    let logList = log;

    logList.push({
        level: 'debug',
        text: 'Start running Kruskal...'
    });

    const addStep = (id, type) => {
        if (edgeRenderQueue.length === timeStep) {
            /* add timeStep */
            const time = [{
                id: id,
                type: type
            }];
            edgeRenderQueue.push(time);
        } else {
            /* add step to timeStep */
            const time = edgeRenderQueue[edgeRenderQueue.length - 1];
            edgeRenderQueue[edgeRenderQueue.length - 1] = time.concat({
                id: id,
                type: type
            });
        }
    }
    //end vis

    /* sort edges */
    edges.sort((edgeA, edgeB) => {
        comparing++;
        if (edgeA.weight < edgeB.weight) {
            return -1;
        } else if (edgeA.weight > edgeB.weight) {
            return 1;
        } else {
            return 0;
        }
    });

    for (let i = 0; i < edges.length; i++) {
        //begin vis
        comparing++;
        addStep(edges[i].id, 'edgeVisited');
        timeStep++;
        //end vis

        const u = edges[i].source;
        const v = edges[i].target;
        if (!unionFind.connected(u, v)) {
            unionFind.union(u, v);
            f = f.add(edges[i].id);
        }

        //begin vis
        logList.push({
            level: 'info',
            text: 'Edge '
                + nodes.find(node => node.id === edges[i].source).label
                + ' -> '
                + nodes.find(node => node.id === edges[i].target).label
                + ' (w='
                + edges[i].weight
                +') visited.'
        });

        /* handle UF update */
        //end vis
    }

    let MST = [];

    for (const item of f) {
        MST.push(item);
    }

    //begin vis
    MST.forEach(id => {
        addStep(id, 'edgeMST');
    });

    logList.push({
        level: 'warning',
        text: 'Total time steps: ' + edgeRenderQueue.length
    });

    setHistory({
        ...history,
        kruskal: (comparing)
    });

    logList.push({
        level: 'debug',
        text: 'Kruskal completed. Start rendering...'
    });

    /* handle render */

    for (let i = 0; i < edgeRenderQueue.length; i++) {
        setTimeout(() => {
            handleHighlightEdgesInQueue(edgeRenderQueue[i]);
        }, speed * i);
    }

    timeStep++;

    setTimeout(() => {
        setDisableAll(false);
    }, speed * timeStep);
    //end vis
};