/**
 * Johnson's Algorithm
 * @author Yiting Liu
 */

export const johnson = (data, from, to, speed, handleHighlightEdgesInQueue, setDisableAll,  setLog, log, history, setHistory) => {
    //begin vis
    setDisableAll(true);
    let timeStep = 0;
    let comparing=0;

    let edgeRenderQueue = [];
    /* edgeRenderQueue: [timeStep: [step: {id, type}, ...], ...] */

    let logList = log;

    logList.push({
        level: 'debug',
        text: 'Start running Johnson...'
    });

    const addStep = (id, type) => {
        if (edgeRenderQueue.length === timeStep) {
            /* add timeStep */
            const time = [{
                id: id,
                type: type
            }];
            edgeRenderQueue.push(time);
        } else {
            /* add step to timeStep */
            const time = edgeRenderQueue[edgeRenderQueue.length - 1];
            edgeRenderQueue[edgeRenderQueue.length - 1] = time.concat({
                id: id,
                type: type,
            });
        }
    };
    //end vis

    const n = data.nodes.length;
    let dist = new Array(n+1).fill().map(() => new Array(n).fill(Infinity));
    let prev = new Array(n+1).fill().map(() => new Array(n).fill(-1));

    const getNodeNumById = (id) => {
        for (let i = 0; i < data.nodes.length; i++) {
            if (data.nodes[i].id === id) {
                return i;
            }
        }

        return -1;
    }

    const getNodeIdByNum = (num) => {
        return data.nodes.find(node => getNodeNumById(node.id) === num).id;
    }

    data.edges.forEach((edge) => {
        //begin vis
        addStep(edge.id, 'edgeVisited');
        timeStep++;
        //end vis

        dist[getNodeNumById(edge.source)][getNodeNumById(edge.target)] = edge.weight;
        prev[getNodeNumById(edge.source)][getNodeNumById(edge.target)] = getNodeNumById(edge.source);

        //begin vis

        logList.push({
            level: 'info',
            text: 'Edge '
                + data.nodes.find(node => node.id === data.edges.find(e => e.id === edge.id).source).label
                + ' -> '
                + data.nodes.find(node => node.id === data.edges.find(e => e.id === edge.id).target).label
                + ' (w='
                + data.edges.find(e => e.id === edge.id).weight
                +') visited.'
        });
        /* handle array update */

        //end vis
    });
    for (let i = 0; i < n+1; i++) {
        dist[i][i] = 0;
        prev[i][i] = i;
        dist[n][i]=0;
        prev[n][i]=n;
        //begin vis
        /* handle array update */
        //end vis
    }

    let weight=dist;
    const begin=getNodeNumById(from);
    for (let j = 0; j < n; j++) {
        for (let k = 0; k < n+1; k++) {
            for(let u=0;u<n+1;u++){
                if (dist[begin][u] > dist[begin][k] + weight[k][u]) {
                    dist[begin][u] = dist[begin][k] + weight[k][u];
                    prev[begin][u] = prev[k][u];

                    //begin vis
                    /* handle array update */
                    //end vis
                }
                comparing++;
            }

        }
    }
    let hasNegativeCycle = false;
    for (let i = 0; i < n+1; i++) {
        for (let j = 0; j < n + 1; j++) {
            if (dist[n][i] + dist[i][j] < dist[n][j]) {
                hasNegativeCycle = true;
            }
            comparing++;
        }
    }
    if (hasNegativeCycle) {
        return { error: "The graph contains negative-weight cycle." };
    }
    data.edges.forEach((edge) => {
        dist[getNodeNumById(edge.source)][getNodeNumById(edge.target)] = dist[getNodeNumById(edge.source)][getNodeNumById(edge.target)]+dist[getNodeNumById(edge.source)][n]-dist[getNodeNumById(edge.target)][n];
        comparing++;
    });

    const INF = Infinity;
    function dijkstra(dist, startNode,num) {
        const dists = new Array(num+1).fill(INF); // 存储起点到每个节点的最短距离
        const prevs = new Array(num+1).fill(-1); // 存储路径上每个节点的前一个节点
        const visited = new Array(num+1).fill(false); // 记录每个节点是否已经被访问
        dists[startNode] = 0;
        for (let i = 0; i < num+1; i++) {
            let minDist = INF;
            let u=0;
            for (let j = 0; j < num+1; j++) {
                if (!visited[j] && dists[j] < minDist) {
                    minDist = dists[j];
                    u = j;
                }
                comparing++;
            }

            visited[u] = true;

            for (let v = 0; v < n+1; v++) {
                if (dist[u][v] !== INF) {
                    const newDist = dists[u] + dist[u][v];
                    if (newDist < dists[v]) {
                        dists[v] = newDist;
                        prevs[v] = u;
                    }
                }
                comparing++;
            }
        }
        console.log(dists);
        return { dists };
    }
    //const numArr1 = from.match(/[+-]?\d+(\.\d+)?/g);
    //const numArr2 = to.match(/[+-]?\d+(\.\d+)?/g);
    //const a=parseInt(numArr1);
    //const b=parseInt(numArr2);
    const a=getNodeNumById(from);
    const b=getNodeNumById(to);
    console.log(a);
    console.log(b);
    dist[a][b] = dijkstra(dist,a,data.nodes.length)[b] + dist[b][n] - dist[a][n];

    const pathRecons = (u, v) => {
        let path = [];

        if (prev[u][v] === -1) {
            return [];
        }

        path.push(v);

        while (u !== v) {
            v = prev[u][v];
            path.unshift(v);
        }

        return path;
    }

    const pathNodeNums = pathRecons(getNodeNumById(from), getNodeNumById(to));
    const pathNodeLabels = pathNodeNums.map(num => data.nodes[num].label);


    //begin vis
    for (let i = 0; i < pathNodeNums.length - 1; i++) {
        const edgeId = getEdgeId(data, getNodeIdByNum(pathNodeNums[i]), getNodeIdByNum(pathNodeNums[i + 1]));
        addStep(edgeId, 'edgeShortest');
    }

    let shortestPathText = 'Shortest path: ';

    for (let i = 0; i < pathNodeLabels.length; i++) {
        shortestPathText = shortestPathText + pathNodeLabels[i];
        if (i !== pathNodeLabels.length - 1) {
            shortestPathText = shortestPathText + ' -> ';
        } else {
            shortestPathText = shortestPathText + '.';
        }
    }

    logList.push({
        level: 'info',
        text: shortestPathText
    });

    logList.push({
        level: 'warning',
        text: 'Total time steps: ' + edgeRenderQueue.length
    });

    setHistory({
        ...history,
        johnson: (comparing)
    });

    logList.push({
        level: 'debug',
        text: 'Johnson completed. Start rendering...'
    });


    for (let i = 0; i < edgeRenderQueue.length; i++) {
        setTimeout(() => {
            handleHighlightEdgesInQueue(edgeRenderQueue[i]);
        }, speed * i);
    }

    timeStep++;

    setTimeout(() => {
        setDisableAll(false);
    }, speed * timeStep);

    setLog(logList);
    //end vis
};

const getEdgeId = (data, from, to) => {
    const edge = data.edges.find(edge => (edge.source === from && edge.target === to));
    if (edge === undefined) {
        return '';
    } else {
        return edge.id;
    }
};
